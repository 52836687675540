'use strict';

define(["AudioZoneV2ControlEnums", "SoundsuitAccountManager"], function (AudioZoneV2ControlEnums, SoundsuitAccountManager) {
    class SoundsuitManagementScreen extends GUI.TableViewScreenV2 {
        constructor(details) {
            super($('<div />'));
            applyMixins(this, StateHandler.Mixin);
            this.details = details;
            this.control = this.details.control;
            this.accounts = [];
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments) || true).then(function () {
                return this.control.audioserverComp.connectionReadyDef.promise.then(function () {
                    return this.control.audioserverComp.getAvailableServices().promise.then(function (availableServices) {
                        this.service = availableServices.find(function (service) {
                            return service.cmd === MusicServerEnum.Service.SOUNDSUIT;
                        });
                    }.bind(this));
                }.bind(this));
            }.bind(this));

        }

        viewWillAppear() {
            return Q(super.viewWillAppear(...arguments) || true).then(function () {
                this._unblockFn = this.isInAmbientMode() ? SandboxComponent.blockScreensaver() : SandboxComponent.blockAmbientAndScreensaver();
                this.serviceChangeReg = this.control.audioserverComp.registerForServiceChanges(this._onServicesChanged.bind(this, false));
                return Q.all([this._onServicesChanged(true)]).then(this.reloadTable.bind(this));
            }.bind(this));
        }

        viewWillDisappear() {
            this._unblockFn && this._unblockFn();
            this.serviceChangeReg && this.serviceChangeReg(); // unregister from service changes.

            this.serviceChangeReg = null;

            super.viewWillDisappear(...arguments);
        }

        titleBarText() {
            return SOUNDSUIT_TITLE;
        }

        setTableContent() {
            this.tableContent = [this._getAddSection()];
            this.tableContent.pushObject(this._getAccountsSection());

            return super.setTableContent(...arguments);
        }

        _getAddSection() {
            return {
                rows: [{
                    content: {
                        leftIconSrc: Icon.TRANSLUCENT_ADD,
                        leftIconColor: window.Styles.colors.green,
                        title: _("audio-server.soundsuit.add-account"),
                        clickable: true
                    },
                    type: GUI.TableViewV2.CellType.GENERAL,
                    action: function action() {
                        this.control.audioserverComp.checkForOutdatedLoginFlowFirmware().then(upToDate => {
                            if (upToDate) {
                                SoundsuitAccountManager.shared(this.control).showAddSoundsuitScreen(this.service);
                            }
                        });
                    }.bind(this)
                }]
            };
        }

        _getAccountsSection() {
            if (this.accounts.length) {
                return {
                    rows: this.accounts.map(function (account) {
                        return {
                            content: {
                                leftIconSrc: account.iconSrc,
                                title: account.name,
                                subtitle: account.user,
                                rightIconSrc: Icon.SETTINGS_SMALL,
                                clickable: true
                            },
                            type: GUI.TableViewV2.CellType.GENERAL,
                            action: function () {
                                this._showScreen(AudioZoneV2ControlEnums.ScreenState.SETTINGS_SERVICES_SOUNDSUIT_ACCOUNT, {
                                    control: this.control,
                                    service: account
                                });
                            }.bind(this)
                        };
                    }.bind(this))
                };
            }
        }

        _showScreen(target, detail) {
            this.ViewController.ViewController.showState(target, null, detail);
        }

        _getAccounts(zones) {
            let accountMap = {}, account;
            zones.forEach(zone => {
                account = accountMap[zone.user] || cloneObject(zone);
                account.name = SOUNDSUIT_TITLE;
                account[MusicServerEnum.Attr.SERVICE.ID] = zone[MusicServerEnum.Attr.SERVICE.ID].split(":")[0];
                account[MusicServerEnum.Attr.SERVICE.UID] = MusicServerEnum.Service.SOUNDSUIT + "/" + account[MusicServerEnum.Attr.SERVICE.ID];
                account.zones = account.zones || [];
                account.zones.push(cloneObject(zone));

                accountMap[zone.user] = account;
            });

            return Object.values(accountMap);
        }

        _onServicesChanged(isInitial) {
            return this.control.audioserverComp.getCurrentServices().promise.then(function (services) {
                var tmpAccounts = cloneObject(this.accounts);
                this.accounts = this._getAccounts(services.filter(function (service) {
                    return service.cmd === MusicServerEnum.Service.SOUNDSUIT;
                }));

                if (this.control.audioserverComp.Feature.SERVICE_FOLLOW & !isInitial && JSON.stringify(tmpAccounts) !== JSON.stringify(this.accounts)) {
                    var newAccount = this.accounts.find(function (account) {
                        return !tmpAccounts.find(function (tmpAcc) {
                            return SoundsuitAccountManager.getUserUnique(tmpAcc) === SoundsuitAccountManager.getUserUnique(account);
                        }.bind(this));
                    }.bind(this));

                    if (newAccount) {
                        this.processWhenVisible(function () {
                            this.ViewController.dismiss();
                            SoundsuitAccountManager.shared(this.control).showOnboardingForUser(newAccount);
                        }.bind(this));
                    }
                }

                return this.reloadTable();
            }.bind(this));
        }

    }

    Controls.AudioZoneV2Control.SoundsuitManagementScreen = SoundsuitManagementScreen;
    return Controls.AudioZoneV2Control.SoundsuitManagementScreen;
});
