'use strict';

define(["ControlActionCellsScreen", "ClimateUsControlEnums", "ClimateUsInfoView"], function (ControlActionCellsScreen, CtrlEnums, ClimateUsInfoView) {
    return class ClimateUsControlActionScreen extends ControlActionCellsScreen {
        constructor() {
            super(...arguments);
        }

        getContextMenuOptions(states) {
            const options = super.getContextMenuOptions(states);
            return this.control.hasServiceModeCapability ? options.concat({
                title: _("controls.irc.mode.service"),
                action: () => this._handleServiceModeTapped(states)
            }) : options;
        }

        _handleServiceModeTapped = (states) => {
            if (states.universalIsLocked) {
                NavigationComp.showPopup({
                    title: _("controls.irc.mode.service"),
                    message: _("controls.climate.service-mode.unavailable"),
                    buttonOk: _('okay'),
                    icon: Icon.CAUTION,
                    color: window.Styles.colors.red,
                    type: PopupType.GENERAL
                });
            } else {
                let enableServiceModeCmd = Commands.format(Commands.CLIMATE_CONTROLLER.SET_SERVICE_MODE, CtrlEnums.ServiceModeSettings.STANDBY);
                this.control.sendCommand(enableServiceModeCmd);
            }
        }

        receivedStates(states) {
            return super.receivedStates(states).then(() => {
                Feature.HVAC_CLIMATE_CONTROLLER_INTEGRATION && this._updateServiceMode(states);
            });
        }

        _updateServiceMode(states) {
            if (states.universalIsLocked) {
                this.toggleCustomInfoView(false);
            } else if (states.serviceMode !== 0) {
                this.toggleCustomInfoView(false);
            } else if (states.serviceMode === 0) {
                this.toggleCustomInfoView(true);
            }
        }

        /**
         * This method can be overwritten, if a view is returned it will be shown inside the customInfoBox!
         * @return {null}
         */
        getCustomInfoView() {
            return new ClimateUsInfoView(this.control, !this.control.areOpModesControlledByLogic() ? this._openModeSelector.bind(this) : null, this._openTemperaturLimitsScreen.bind(this), this._openIrcList.bind(this));
        }

        getActionSections(states) {
            var sections = [],
                overViewSection = {
                    rows: []
                },
                stopModeTimerSection = this._getStopModeTimerSection(states),
                stopFanSection = this._getStopFanTimerSection(states),
                stopEmergencyTimerSection = this._getStopEmergencyHeatTimerSection(states),
                controlsSection = this._getControlsSection(states),
                statusItemCells = this._getStatusCells(states);

            overViewSection.rows = overViewSection.rows.concat(statusItemCells);
            sections.push(overViewSection);
            stopModeTimerSection && sections.push(stopModeTimerSection);
            stopFanSection && sections.push(stopFanSection);
            stopEmergencyTimerSection && sections.push(stopEmergencyTimerSection);
            controlsSection && sections.push(controlsSection);
            return states.serviceMode ? [...super.getActionSections(states)] : sections;
        }

        getAdditionalSection(states) {
            var section = super.getAdditionalSection(...arguments) || {
                    rows: []
                },
                ircListCell;
            ircListCell = this.getSubmenuCell(Feature.HVAC_CLIMATE_CONTROLLER_INTEGRATION ? _('controls.climate.us.rooms') : _("controls.climate.us.room-controllers"), this._openIrcList.bind(this));
            section.rows.unshift(ircListCell);
            return states.serviceMode ? [] : section;
        }

        shouldDisplayStateIcons() {
            return this.control.getStates().serviceMode !== 0 || false;
        }

        getTitle() {
            return this.control.getStates()?.serviceMode !== 0 ? _("controls.irc.mode.service") : super.getTitle();
        }

        setSubtitle() {
            return null;
        }

        // ----------------------------------------------------------------------------------------
        // Table Content Creation
        // ----------------------------------------------------------------------------------------
        _getStatusCells(states) {
            var cells = [];

            if (states.isSwitching) {
                this._pushStatus(cells, states.switchingText, states.isSwitchingToCooling ? window.Styles.colors.blue : window.Styles.colors.orange);
            }

            return cells;
        }

        _pushStatus(target, text, color, action) {
            target.push({
                type: GUI.TableViewV2.CellType.TEXT,
                content: {
                    text: text,
                    centered: true,
                    color: color,
                    clickable: !!action
                },
                action: action
            });
        }

        // ----------------------------------------------------------------------------------------
        //     Stopping Timers
        // ----------------------------------------------------------------------------------------
        _getStopSection(texts, buttonTitle, buttonAction) {
            var statusButtonCell;
            statusButtonCell = {
                type: GUI.TableViewV2.CellType.STATUS_BUTTON_CELL,
                content: {
                    texts: texts,
                    buttonTitle: buttonTitle,
                    buttonAction: buttonAction
                }
            };
            return {
                rows: [statusButtonCell]
            };
        }

        _getStopModeTimerSection(states) {
            var section = null;

            if (states.modeTimerActive) {
                section = this._getStopSection(states.modeTimerActiveText, _("controls.climate.us.mode.stop-timer"), this._handleStopModeTimer.bind(this));
            }

            return section;
        }

        _getStopFanTimerSection(states) {
            var section = null;
            
            if(Feature.HVAC_CLIMATE_CONTROLLER_INTEGRATION && !this.control.hasFanCapability) {
                return section;
            } else if (states.fanTimerActive && this.control.hasFan()) {
                section = this._getStopSection(states.fanTimerActiveText, _("controls.climate.us.fan.stop"), this._handleStopFan.bind(this));
            }

            return section;
        }

        _getStopEmergencyHeatTimerSection(states) {
            var section = null;

            if (Feature.HVAC_CLIMATE_CONTROLLER_INTEGRATION && this.control.hasEmergencyHeatCapability && states.emergencyHeatTimerActive || states.emergencyHeatActive) {
                section = this._getStopSection(states.emergencyHeatTimerActiveText, _("controls.climate.us.emergency-heat-stop-timer"), this._handleEmergencyHeaterStop.bind(this));
            }

            return section;
        }

        // ----------------------------------------------------------------------------------------
        //     Controls
        // ----------------------------------------------------------------------------------------
        _getControlsSection(states) {
            var rows = [],
                section = null;

            if (!states.modeTimerActive) {
                rows.push(this._getActiveModeCell(states));
            }

            rows.pushObject(this._getOutsideTempSettingsCell(states));

            if(Feature.HVAC_CLIMATE_CONTROLLER_INTEGRATION && !this.control.hasFanCapability) {
            } else if (!states.fanTimerActive && this.control.hasFan()) {
                rows.push(this._getStartFanCell(states));
            }

            if (this.control.isEmergencyHeaterControllable()) {
                rows.push(this._getEmergencyHeatCell(states));
            }

            if (rows.length > 0) {
                section = {
                    rows: rows
                };
            }

            return section;
        }

        _getActiveModeCell(states) {
            var canBeChanged = !this.control.areOpModesControlledByLogic();
            return this._createControlCell(_("controls.climate.us.mode.title"), states.modeName, this._openModeSelector.bind(this), !canBeChanged, canBeChanged ? null : _("controlled-via-logic"));
        }

        _getEmergencyHeatCell(states) {
            var statusText = states.emergencyHeatActive ? _("on") : _("off");
            return this._createControlCell(_("controls.climate.us.emergency-heat"), statusText, this._openEmergencyHeaterScreen.bind(this));
        }

        _getOutsideTempSettingsCell(states) {
            if (!this.control.usesOutsideTemperature()) {
                return null;
            }
            var title = _("controls.climate.set.temp-boundary", {
                count: this.control.canHeatAndCool() ? 2 : 1
            });

            var subtitle = null;
            if (states.outdoorTempInfo === CtrlEnums.OutdoorTempInfo.NO_DATA) {
                subtitle = _("controls.climate.tempinfo.no-data")
            } else if (states.outdoorTempInfo === CtrlEnums.OutdoorTempInfo.NOT_ENOUGH_DATA) {
                var temp = this.control.getOutsideTempModeName();
                subtitle = _("controls.climate.tempinfo.not-enough-data", {temp})
            }

            return this._createControlCell(
                title,
                null,
                this._openTemperaturLimitsScreen.bind(this),
                null,
                null,
                (!this.control.hasOutsideTemperature() ? { rightIconSrc: Icon.WARNING, rightIconColor: Color.YELLOW_FIXED } : {})
                // !this.control.hasOutsideTemperature(),
                // subtitle
            );
        }

        _getStartFanCell(states) {
            var fanStateText;

            if (states.fanTimerActive) {
                fanStateText = _("controls.climate.us.fan.state-on");
            } else {
                fanStateText = _("controls.climate.us.fan.state-auto");
            }

            return this._createControlCell(_("controls.climate.us.fan.title"), fanStateText, this._openFanScreen.bind(this));
        }

        _createControlCell(title, stateText, action, disabled, subtitle, icon = undefined) {
            return {
                type: GUI.TableViewV2.CellType.GENERAL,
                content: {
                    title: title,
                    subtitle: subtitle,
                    disabled: disabled,
                    clickable: !disabled,
                    disclosureIcon: !disabled,
                    disclosureText: stateText,
                    disclosureColor: Color.TEXT_HINT_A,
                    ...icon
                },
                action: action
            };
        }

        // ------------------------------------------------------------------------------------
        //    Opening sub-screens
        // ------------------------------------------------------------------------------------
        _openFanScreen() {
            var details = {
                title: _("controls.climate.us.fan.title"),
                options: [{
                    title: _("controls.climate.us.fan.state-on"),
                    selected: false
                }, {
                    title: _("controls.climate.us.fan.state-auto"),
                    selected: true
                }],
                onSelectionChanged: function (index) {
                    this._handleStartFanTimer(index === 0 ? -1 : 0);
                }.bind(this),
                onTimerStarted: this._handleStartFanTimer.bind(this),
                timerDetails: this._getFanTimerDetails()
            };
            this.ViewController.showState(ScreenState.SELECTOR_TIMER_SCREEN, null, details);
        }

        _getFanTimerDetails() {
            var details = {};
            details.title = _("controls.climate.us.fan.start");
            details.returnTimepoint = true;
            details.offerAlways = false;
            details.pickerText = _("controls.climate.us.fan.on-until");
            return details;
        }

        _openEmergencyHeaterScreen() {
            var isOn = this.control.getStates().isEmergencyHeaterActive;
            var details = {
                title: _("controls.climate.us.emergency-heat"),
                options: [{
                    title: _("on"),
                    selected: isOn
                }, {
                    title: _("off"),
                    selected: !isOn
                }],
                onSelectionChanged: function (index) {
                    this._handleEmergencyHeatTimer(index === 0 ? -1 : 0);
                }.bind(this),
                onTimerStarted: this._handleEmergencyHeatTimer.bind(this),
                timerDetails: this._getEmergencyHeatTimerDetails()
            };
            this.ViewController.showState(ScreenState.SELECTOR_TIMER_SCREEN, null, details);
        }

        _getEmergencyHeatTimerDetails() {
            var details = {};
            details.returnTimepoint = true;
            details.offerAlways = false;
            return details;
        }

        _openIrcList() {
            this.ViewController.showState(CtrlEnums.ScreenState.IRC_LIST, null, {
                control: this.control
            });
        }

        _openTemperaturLimitsScreen() {
            this.ViewController.showState(CtrlEnums.ScreenState.OUTSIDE_TEMP_SCREEN, null, {
                control: this.control
            });
        }

        _openModeSelector() {
            this.ViewController.showState(CtrlEnums.ScreenState.MODE_SELECTOR, null, {
                control: this.control
            });
        }

        // ----------------------------------------------------------------------------------------
        // Commands
        // ----------------------------------------------------------------------------------------
        _handleEmergencyHeater(active) {
            this.sendCommand(Commands.format(Commands.CLIMATE_CONTROLLER_US.USE_EMERGENCY, active));
        }

        _handleEmergencyHeatTimer(activeUntil) {
            this.sendCommand(Commands.format(Commands.CLIMATE_CONTROLLER_US.START_EMERGENCY_TIMER, activeUntil));
        }

        _handleEmergencyHeaterStop() {
            this._handleEmergencyHeatTimer(0);
        }

        _handleStartFanTimer(activeUntil) {
            this.sendCommand(Commands.format(Commands.CLIMATE_CONTROLLER_US.VENTILATION_TIMER, activeUntil));
        }

        _handleStopFan(active) {
            this._handleStartFanTimer(0);
        }

        _handleStopModeTimer() {
            this.sendCommand(Commands.format(Commands.CLIMATE_CONTROLLER_US.START_MODE_TIMER, 0, 0, 0));
        }

    };
});
