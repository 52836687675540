'use strict';

define("AudioZoneV2DetailedContentBase", [], function () {
    class AudioZoneV2DetailedContentBase extends GUI.TableViewScreenV2 {
        //region Getter
        get RIGHT_SIDE_BUTTON() {
            return {
                CONTEXT_MENU: "right-side__touch-area--ctx-menu",
                SEARCH: "right-side__touch-area--search",
                EMPTY_HISTORY: "right-side__touch-area--empty-history"
            };
        } //endregion Getter


        constructor(details) {
            super($('<div />'));
            applyMixins(this, MediaContextMenuHandlerV2.Mixin, ContextMenuHandler.Mixin);
            this.details = details;
            this.control = details.control;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments);
        }

        getAnimation() {
            return HD_APP ? AnimationType.HD_OVERLAY_FULLSCREEN : AnimationType.OVERLAY;
        }

        // TableView DataSource Methods
        styleForTableView() {
            return TableViewStyle.COMFORT_MODE_2020;
        }

        titleBarActionRight(buttonId) {
            if (buttonId === this.RIGHT_SIDE_BUTTON.CONTEXT_MENU) {
                this._showContextMenuForItem(this);
            } else if (buttonId === this.RIGHT_SIDE_BUTTON.SEARCH) {
                this.ViewController.rightAction();
            }
        }

        titleBarText() {
            return super.titleBarText(...arguments);
        }

        viewControllerTitleText() {
            if (HD_APP) {
                return this.titleBarText();
            } else {
                return this.control.getName();
            }
        }

        viewControllerSubtitleText() {
            return null;
        }

        /**
         * Helper to safeguard against errors when the element of the ViewController isn't ready.
         * @param attr
         * @param val
         */
        setViewControllerHtmlAttr(attr, val) {
            if (this.ViewController && this.ViewController.getElement()) {
                this.ViewController.getElement().attr(attr, val);
            }
        }

        getRightVCButtonIcon() {
            if (this.hasCtxMenu()) {
                return Icon.Buttons.MORE2;
            }
        }

        getRightVCButtonIconFn() {
            return this.titleBarActionRight.bind(this, this.RIGHT_SIDE_BUTTON.CONTEXT_MENU);
        }

        _checkTitlebar() {
            if (this.ViewController instanceof GUI.AddMediaViewControllerSpotifyOnboarding && !this.ViewController.canNavigateBack()) {
                return;
            } else if (this.ViewController instanceof GUI.AddMediaViewControllerV2PlaylistPicker) {
                if (this.ViewController.canNavigateBack()) {
                    // since the view-lifecycle rework, the viewControllers canNavigateBack will only work after the
                    // view lifecycles have passed, as then the history, viewStack, currentView and so on are properly
                    // set.
                    developerAttention(this.viewId + ": _checkTitleBar - adopted behaviour, previously a titleBar was shown in this case!");
                    console.warn(this.viewId, "devAtt: ", getStackObj());
                }

                return; // We don't want a titleBar in this case. It is handled by the ViewController...
            } else if (this.ViewController instanceof GUI.AudioZoneV2ControlDetailedContentViewController || this.ViewController instanceof GUI.AddMediaViewControllerV2Base) {
                var titleBarConfig = this.titleBarConfig();

                if (((!HD_APP) || this.ViewController instanceof GUI.AddMediaViewControllerV2Base) && titleBarConfig) {
                    this._titleBar = new GUI.LxTitleBar(titleBarConfig);

                    this._titleBar.setTitleBarSideTexts(this.titleBarText());

                    this.prependSubview(this._titleBar);

                    this._applyRightSideButtons();

                    this._titleBar.element.addClass("minor-title-bar");
                }
            } else {
                return Q(super._checkTitlebar(...arguments)).then(function () {
                    this._applyRightSideButtons();
                }.bind(this));
            }
        }

        titleBarConfig() {
            return {
                leftSide: this._getLeftSideForTitleBar(),
                rightSide: this._getRightSideForTitleBar(),
                rightSideDiv: "<div>" + (this._getRightSideTitleBarButtons() || []).join("") + "</div>"
            };
        }

        _getLeftSideForTitleBar() {
            if ((!HD_APP) || this.ViewController instanceof GUI.ModalViewController || this.ViewController.history.stack.length > 1) {
                return super._getLeftSideForTitleBar(...arguments);
            } else {
                return TitleBarCfg.TEXT;
            }
        }

        _getRightSideForTitleBar() {
            return TitleBarCfg.CUSTOM_CONTENT;
        }

        _applyRightSideButtons() {
            Object.values(this.RIGHT_SIDE_BUTTON).forEach(function (btnId) {
                var btnElm = this._titleBar.element.find("." + btnId),
                    btn;

                if (btnElm[0]) {
                    btn = new GUI.LxButton(this, btnElm);
                    this.addToHandledSubviews(btn);
                    btn.onButtonTapped = this.titleBarActionRight.bind(this, btnId);
                }
            }.bind(this));
        }

        _getRightSideTitleBarButtons() {
            var btns = [];

            if (!(this.ViewController instanceof GUI.AddMediaViewControllerV2Base)) {
                if (this.hasCtxMenu()) {
                    btns.push(this._getRightSideTitleBarButtonElm(this.RIGHT_SIDE_BUTTON.CONTEXT_MENU, Icon.Buttons.MORE_HORIZONTAL));
                }
            }

            return btns;
        }

        _getRightSideTitleBarButtonElm(btnClass, icon, onButtonTapped) {
            return "<div class='right-side__touch-area clickable " + btnClass + "'>" + ImageBox.getResourceImageWithClasses(icon, "right-side__button") + "</div>";
        }

    }

    Controls.AudioZoneV2Control.AudioZoneV2DetailedContentBase = AudioZoneV2DetailedContentBase;
    return Controls.AudioZoneV2Control.AudioZoneV2DetailedContentBase;
});
