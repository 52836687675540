import {useContext, useState, useEffect, useMemo} from "react";
import NfcCodeTouchControlEnums from "../../nfcCodeTouchControlEnums"
import LxTrackerCell from "../../../trackerControl/trackerView/LxTrackerCell";
import {
    ControlContext,
    LxReactTableView,
    LxControlContentContainer,
    navigatorConfig
} from "LxComponents";

function NfcCodeTouchControlContentHistory({navigation, route}) {
    const {control, states} = useContext(ControlContext)
    const [history, setHistory] = useState(route.params.history);

    useEffect(() => {
        navigation.setOptions(
            navigatorConfig({
                title: _('autopilot.history')
            })
        );

        return () => {};
    }, [])

    useEffect(() => {
        control.getHistory(true).then((history) => {
            setHistory(history);
        });
    }, [states.historyDate])

    const _mapEntries = (entry) => {
        return entry.entries.map(detailedEntry => {
            return {
                timestamp: new LxDate(detailedEntry.ts, true),
                text: _prepareHistoryString(detailedEntry)
            }
        })
    }

    const _prepareHistoryString = (entry) => {
        var str = '';

        if (entry.type === NfcCodeTouchControlEnums.HistoryType.CODE_DENIED) {
            return _getUserInfo(entry) + _('user.access-code.denied');
        } else if (entry.type === NfcCodeTouchControlEnums.HistoryType.NFC_DENIED) {
            return _getUserInfo(entry) + _('nfc.nfc-tag.denied');
        } else if (entry.type === NfcCodeTouchControlEnums.HistoryType.VISU_DENIED) {
            return _getUserInfo(entry) + _('nfc.visu.denied');
        } else if (entry.type === NfcCodeTouchControlEnums.HistoryType.EXT_DECLINED) {
            var prts = [];

            if (entry.extMedium) {
                prts.push(_getUserInfo(entry) + entry.extMedium);
            } else if (entry.user) {
                prts.push(entry.user);
            }
            if (entry.extAuthority) {
                prts.push(_("controls.nfccodetouch.declined-by"));
                prts.push(entry.extAuthority);
            } else {
                prts.push(_("controls.nfccodetouch.declined"));
            }
            return prts.join(" ");
        }

        if (entry.output !== NfcCodeTouchControlEnums.OutputBitmask.UNAUTHORIZED && entry.outputName) {
            str = entry.outputName + " " + _('via') + " ";
        }

        if (entry.type === NfcCodeTouchControlEnums.HistoryType.EXT_ACCEPTED) {
            if (entry.user && entry.extMedium) {
                str += _getUserInfo(entry) + entry.extMedium;
                if (entry.extAuthority) {
                    str += " (" + entry.extAuthority + ")";
                }
            } else if (entry.user) {
                str += entry.user;
                if (entry.extAuthority) {
                    str += " (" + entry.extAuthority + ")";
                }
            } else if (entry.extAuthority && entry.extMedium) {
                str += entry.extAuthority + " (" + entry.extMedium + ")";
            } else {
                var prts = [];
                entry.extAuthority && prts.push(entry.extAuthority);
                entry.extMedium && prts.push(entry.extMedium);
                str += prts.join(" ");
            }
        } else if (entry.user) {
            str += _getUserInfo(entry);

            switch (entry.type) {
                case NfcCodeTouchControlEnums.HistoryType.CODE_USER:
                    str += _('user.access-code');
                    break;

                case NfcCodeTouchControlEnums.HistoryType.NFC_USER:
                    str += _('nfc.nfc-tag');

                    if (entry.description) {
                        str += ' "' + entry.description + '"';
                    }

                    break;

                case NfcCodeTouchControlEnums.HistoryType.VISU:
                    str += _('visu');
                    break;

                default:
                    str += NOT_AVAILABLE;
                    break;
            }
        } else {
            switch (entry.type) {
                case NfcCodeTouchControlEnums.HistoryType.CODE:
                    str += _('user.access-code') + ' "' + entry.description + '"';
                    break;

                case NfcCodeTouchControlEnums.HistoryType.CODE_AMBIGUOUS:
                    str += _('user.access-code') + " " + "•".repeat(5);
                    break;

                case NfcCodeTouchControlEnums.HistoryType.NFC_AMBIGUOUS:
                    str += _('user.nfc-tag') + " " + "•".repeat(5);
                    break;

                case NfcCodeTouchControlEnums.HistoryType.NFC:
                    str += _('nfc.nfc-tag') + ' "' + entry.description + '"';
                    break;

                default:
                    str += NOT_AVAILABLE;
                    break;
            }
        }

        return str;
    }

    const _getUserInfo = (entry) => {
        if (entry.user) {
            return entry.user + " ";
        } else {
            return "";
        }
    }

    const historyContent = useMemo(() => {
        let tableContent = [];
        tableContent = history.map(entry => {
            let mappedEntries = _mapEntries(entry);

            return {
                headerStrongTitle: moment.unix(entry.ts).format("DD. MMMM YYYY"),
                headerTitleStyle: {flex: 1},
                rows: [{
                    type: "CustomCell",
                    comp: LxTrackerCell,
                    props: {
                        entries: mappedEntries
                    }
                }]
            }
        })


        return tableContent;
    }, [history])

    return <LxControlContentContainer>
        <LxReactTableView tableContent={historyContent} styles={styles.tableView}/>
    </LxControlContentContainer>

}

const styles = {
    tableView: {
        List: {
            paddingHorizontal: 0,
            maxWidth: "100%"
        }
    }
}

export default NfcCodeTouchControlContentHistory
