import { globalCacheDB } from './globalCacheDB';

export const cacheImage = async (url) => {
    try {
        new URL(url);
        const res = await fetch(url);
        const blob = await res.blob();
        await globalCacheDB.transaction('rw', [globalCacheDB.audioZoneImages], async () => {
            const existingBlob = await globalCacheDB.audioZoneImages.get(url);
            if (existingBlob) {
                if(existingBlob.timestamp < new Date().getTime() - 1000 * 60 * 60 * 24 * 7) { // 1 week
                    Debug.IndexDB && console.log('[AudioZoneImageCache] ', 'Image already cached and is less than 1 week old', url);
                    return;
                } else {
                    Debug.IndexDB && console.log('[AudioZoneImageCache] ', 'Image already cached but is older than 1 week, updating cache', url);
                    await globalCacheDB.audioZoneImages.update(url, { blob, timestamp: new Date().getTime() });
                    Debug.IndexDB && console.log('[AudioZoneImageCache] ', 'Updated cache', url);
                }
                Debug.IndexDB && console.log('[AudioZoneImageCache] ', 'Image already cached', url);
                return;
            } else {
                Debug.IndexDB && console.log('[AudioZoneImageCache] ', 'Caching image', url);
                const timestamp = new Date().getTime();
                await globalCacheDB.audioZoneImages.add({ url, blob, timestamp });
                Debug.IndexDB && console.log('[AudioZoneImageCache] ', 'Cached image', url);
            }
        });
        return;
    } catch (error) {
        console.error('Failed to cache image', error);
        throw error;
    }
}

export const getCachedImageBlob = async (url) => {
    try {
        const image = await globalCacheDB.audioZoneImages.get(url);
        if (!image) {
            Debug.IndexDB && console.log('[AudioZoneImageCache] ', 'Image not found in cache, fetching from network', url);
            await cacheImage(url);
            return await getCachedImageBlob(url);
        }
        Debug.IndexDB && console.log('[AudioZoneImageCache] ', 'Fetched image from cache', url);
        return image.blob;
    } catch (error) {
        console.error('Failed to fetch image from cache', error);
        throw error;
    }
}