'use strict';

define([
    "DaytimerControlEnums", "PoolControlEnums", "IRoomControlEnums", "IRoomControllerV2ControlEnums", "IrcFixedSetpointUtil"
], function (
    DaytimerControlEnums, PoolControlEnums, IRoomControlEnums, IRoomControllerV2ControlEnums,
    { Util: FixedSetpointUtil }
) {
    var EMPTY_ENTRY = {
            mode: null,
            equalModes: [],
            needActivate: false
        },
        MINUTES_OF_DAY = 1440,
        MILLISECONDS_OF_DAY = 24 * 60 * 60 * 1000;
    return class DaytimerControlContentEntryDetail extends Controls.ControlContent {
        constructor(details) {
            super(details);
            Object.assign(this, ContextMenuHandler.Mixin);
            this.currentEntry = details.entry ? cloneObjectDeep(details.entry) : cloneObjectDeep(EMPTY_ENTRY);
            this.rawEntry = JSON.stringify(this.currentEntry);
            this.isEditMode = details.isEditMode;

            if (this.isEditMode) {
                this.entryBefore = cloneObjectDeep(this.currentEntry);
            }

            if (!this.currentEntry.from && !this.currentEntry.to) {
                SandboxComponent.getMiniserverTimeInfo(this, function (minutesSinceMidnight) {
                    this.currentEntry.from = this._findNextCycleFor(minutesSinceMidnight);
                }.bind(this), TimeValueFormat.MINUTES_SINCE_MIDNIGHT);
            }

            this.isDayTimer = this.control.type === DaytimerControlEnums.ControlType.DAYTIMER;
            this.isPool = this.control.type === DaytimerControlEnums.ControlType.POOL_DAYTIMER;
            this.isIrc = this.control.type === DaytimerControlEnums.ControlType.IRC_DAYTIMER;
            this.isIrcV2 = this.control.type === DaytimerControlEnums.ControlType.IRCV2_DAYTIMER; // the pools and irc daytimer are passed in here as this.control - so be sure to have the pool or irc
            // control itself ready too.

            this.parentControl = ActiveMSComponent.getControlByUUID(this.control.uuidParent);

            if (this.isPool && !this.isEditMode) {
                // setting initial cycle mode (equal to tempMode in the irc)
                this.currentEntry.cycleMode = PoolControlEnums.PoolCycleMode.FILTER;
            }
        }

        viewDidLoad() {
            return Q(super.viewDidLoad(...arguments)).then(function () {
                this.tableViewDataSource = tableViewDataSource(null, null, this);
                this.tableViewDelegate = tableViewDelegate(null, this);
                this.tableView = new GUI.TableViewV2(this.tableViewDataSource, this.tableViewDelegate);
                this.tableView.getElement().addClass("entry-detail__table-view");
                return this.appendSubview(this.tableView);
            }.bind(this));
        }

        titleBarActionRight() {
            var currRawEntry = JSON.stringify(this.currentEntry),
                success = true;

            if (currRawEntry === this.rawEntry) {
                // Nothing changed
                this.ViewController.navigateBack();
            } else {
                // if the view wasn't dismissed (entry wasn't correct, e.g. start > end), it returns false.
                success = this._addNewEntry();
            }
        }

        viewDidDisappear(viewRemainsVisible) {
            Debug.Control.Daytimer.EntryDetail && console.log("EntryDetail: viewDidDisappear");

            this._removeUpdatingEntries();

            return super.viewDidDisappear(viewRemainsVisible);
        }

        destroy() {
            this._removeUpdatingEntries();

            return super.destroy();
        }

        updateView(details) {
            Debug.Control.Daytimer.EntryDetail && console.log("EntryDetail: updateView");
            super.updateView();

            if (details.hasOwnProperty("equalModes")) {
                this.currentEntry.equalModes = details.equalModes;
            } else if (details.hasOwnProperty("modeNr") && this.isIrc || this.isIrcV2) {
                this.currentEntry.tempMode = details.modeNr;
                this.currentEntry.targetTemp = details.targetTemp;

                if (this.isIrcV2) {
                    this.currentEntry.tempModeName = details.tempModeName;
                }
            } else if (details.hasOwnProperty("cycleMode") && this.isPool) {
                this.currentEntry.cycleMode = details.cycleMode;

                this._updateToTimeOfCurrentEntry();
            }

            this._createAndReloadTableContent();
        }

        getURL() {
            return "EntryDetails";
        }

        getAnimation() {
            return AnimationType.MODAL;
        }

        titleBarText() {
            if (this.isEditMode) {
                return _("controls.daytimer.entry.edit");
            } else {
                return _("controls.daytimer.entry.new");
            }
        }

        titleBarConfig() {
            return {
                leftSide: TitleBarCfg.Button.CLOSE,
                rightSide: TitleBarCfg.Button.TICK,
                style: 'transparent'
            };
        }

        receivedStates(states) {
            // the remaining time state has to be ignored here!
            var newStates = cloneObjectDeep(states);

            if (newStates.hasOwnProperty('remainingTime')) {
                delete newStates.remainingTime;
            }

            if (this.states && JSON.stringify(newStates) === JSON.stringify(this.states)) {
                return;
            }

            Debug.Control.Daytimer.EntryDetail && console.log("EntryDetail: receivedStates");
            this.states = newStates;
            this.allEntries = cloneObjectDeep(states.entries); // avoid modifying the entries from the stateContainer

            this.priorityList = states.usedModes; // find default mode if the user wants to create a new entry

            if (this.currentEntry.mode === null) {
                // mode can be "0" so don't check in this way: !this.currentEntry.mode
                this.currentEntry.mode = states.activeMode;
                this.currentEntry.equalModes.push(states.activeMode.toString());
            }

            if (this.isDayTimer) {
                if (!this.currentEntry.hasOwnProperty("value")) {
                    if (this.control.details.analog) {
                        this.currentEntry.value = states.defaultValue + 1;
                    } else {
                        this.currentEntry.value = 1;
                    }
                }
            } else if (this.isIrc) {
                // setting initial temp mode (equal to cycleMode in the pool)
                if (!(this.currentEntry.tempMode >= IRoomControlEnums.IRCTempMode.ECONOMY)) {
                    // set default tempMode as comfort-temp
                    if (this.control.details.isHeating) {
                        this.currentEntry.tempMode = IRoomControlEnums.IRCTempMode.COMFORT_HEATING;
                    } else if (this.control.details.isCooling) {
                        this.currentEntry.tempMode = IRoomControlEnums.IRCTempMode.COMFORT_COOLING;
                    }
                }

                this.currentEntry.targetTemp = states.activeTemperatures[this.currentEntry.tempMode];
            } else if (this.isIrcV2) {
                if (this.currentEntry.tempMode === undefined) {
                    this.currentEntry.tempMode = IRoomControllerV2ControlEnums.Mode.COMFORT;
                }

                this.currentEntry.tempModeName = this.parentControl.getNameOfIRCTempMode(this.currentEntry.tempMode);
            } else if (this.isPool) {
                this.flushingTime = Math.ceil((states.backwashTime + states.rinseTime) / 60); // time from states are given in seconds
            } // setting "to"-time


            if (!this.currentEntry.to) {
                this._updateToTimeOfCurrentEntry();
            } // initial table view reload


            this._createAndReloadTableContent();
        }

        styleForTableView() {
            return TableViewStyle.GROUPED;
        }

        // Private functions
        _createAndReloadTableContent(onlyCreateTableContent) {
            this.tableContent = [];
            Debug.Control.Daytimer.EntryDetail && console.log("EntryDetail: numberOfSections");

            switch (this.control.type) {
                case DaytimerControlEnums.ControlType.DAYTIMER:
                    this.tableContent = this._createDayTimerTableContent();
                    break;

                case DaytimerControlEnums.ControlType.POOL_DAYTIMER:
                    this.tableContent = this._createPoolControllerTableContent();
                    break;

                case DaytimerControlEnums.ControlType.IRC_DAYTIMER:
                case DaytimerControlEnums.ControlType.IRCV2_DAYTIMER:
                    this.tableContent = this._createIRCTableContent();
                    break;
            }

            if (this._shouldShowDeleteButton()) {
                this.tableContent.push(this._getDeleteButtonSection());
            }

            this.tableViewDataSource.update(this.tableContent);
            this.tableViewDelegate.update(this.tableContent);

            if (!onlyCreateTableContent) {
                this.tableView.reload();
            }
        }

        _shouldShowDeleteButton() {
            var showDeleteButton = this.isEditMode;

            if (this.isIrcV2 && this.currentEntry.tempMode === IRoomControllerV2ControlEnums.Mode.ECO) {
                showDeleteButton = false;
            }

            return showDeleteButton;
        }

        _createDayTimerTableContent() {
            var tableContent = [];
            tableContent.push(this._getOperatingModeSection());
            tableContent.push(this._getTimePickerSection());

            if (this.control.details.analog) {
                tableContent.push(this._getAnalogValueSection());
            }

            tableContent.push(this._getActivationRequiredSection());
            return tableContent;
        }

        _createPoolControllerTableContent() {
            var tableContent = [];
            tableContent.push(this._getCycleSelectionSection());
            tableContent.push(this._getOperatingModeSection());
            tableContent.push(this._getTimePickerSection(true));
            return tableContent;
        }

        /**
         * handles both IRC Controls (IRC and IRCV2)
         * @return {[]}
         * @private
         */
        _createIRCTableContent() {
            var tableContent = [];
            tableContent.push(this._getTemperatureSection());
            tableContent.push(this._getOperatingModeSection());
            tableContent.push(this._getTimePickerSection());
            if (this.isIrcV2 && Feature.IRC_V2021 && this.currentEntry.tempMode !== IRoomControllerV2ControlEnums.Mode.ECO && this.currentEntry.tempMode !== IRoomControllerV2ControlEnums.Mode.ECO_PLUS) { 
                tableContent.push(this._getActivationRequiredSection(true));
            }

            return tableContent;
        }

        _getCycleSelectionSection() {
            var section = {
                rows: []
            };
            section.rows.push({
                content: {
                    title: _("pool.control.cycle"),
                    disclosureIcon: true,
                    disclosureText: this.parentControl.getNameOfPoolCycleMode(this.currentEntry.cycleMode),
                    disclosureColor: this.parentControl.getColorOfPoolCycleMode(this.currentEntry.cycleMode)
                },
                action: function () {
                    this.ViewController.showState(PoolControlEnums.ScreenState.CYCLE_SELECTION, null, {
                        control: this.control,
                        entry: this.currentEntry
                    });
                }.bind(this)
            });
            return section;
        }

        /**
         * This function handles the only difference between IRC and IRCV2
         * @return {{rows: [{action: *, content: {leftIconSrc: string, disclosureIcon: boolean, title: *, disclosureText: string, disclosureColor: *}}]}}
         * @private
         */
        _getTemperatureSection() {
            var disclosureText;

            if (this.isIrcV2) {
                disclosureText = this.currentEntry.tempModeName;
            } else {
                disclosureText = this.parentControl.getNameOfIRCTempMode(this.currentEntry.tempMode) + ' ' + lxFormat(this.control.details.format, this.currentEntry.targetTemp) + SandboxComponent.getTemperatureUnit();
            }

            let tempColor = this.parentControl.getColorOfIRCTempMode(this.currentEntry.tempMode, true);
            let rows = [{
                    content: {
                        leftIconSrc: this.isIrcV2 ? null : Icon.IRC.TEMPERATURE,
                        title: _("temperature"),
                        disclosureIcon: true,
                        disclosureText: disclosureText,
                        disclosureColor: tempColor
                    },
                    action: function () {
                        this.ViewController.showState(this.isIrcV2 ? IRoomControllerV2ControlEnums.ScreenState.TEMPERATURE_SELECTOR : IRoomControlEnums.ScreenState.TEMPERATURE_SELECTION, null, {
                            control: this.control,
                            entry: this.currentEntry
                        });
                    }.bind(this)
                }];

            if (FixedSetpointUtil.isFixedSetpointValue(this.currentEntry.tempMode)) {
                var setpoint = FixedSetpointUtil.extractFromValue(this.currentEntry.tempMode),
                    parentState = this.parentControl.getStates();
                if (setpoint.target === 0) {
                    setpoint.target = parentState ? parentState.tempTarget : 22;
                }
                rows.push({
                    type: GUI.TableViewV2.CellType.SLIDER,
                    content: {
                        title: disclosureText,
                        value: setpoint.target,
                        minValue: parentState.frostProtectTemperature,
                        maxValue: parentState.heatProtectTemperature,
                        minIconSrc: Icon.MINUS,
                        maxIconSrc: Icon.PLUS,
                        valueColor: tempColor,
                        step: this.parentControl.getTempStep(),
                        format: this.parentControl.details.format
                    },
                    sliderDragEnded: function(cell, section, row, tableView, value) { this._setpointTargetChanged(value, setpoint) }.bind(this),
                    sliderClicked: function(cell, section, row, tableView, value) { this._setpointTargetChanged(value, setpoint) }.bind(this),
                    userfriendlyValueForSlider: function(cell, section, row, tableView, value) {
                        if(SandboxComponent.getTemperatureUnit() === TempAppendix(TempUnit.FAHRENHEIT)) { // if temperature is already in fahrenheit, no conversion is needed.
                            return value;
                        }
                        return SandboxComponent.getTemperatureForUI(value); // requried for fahrenheit/celsius conversion.
                    }.bind(this)
                });
            }

            return { rows };
        }

        _setpointTargetChanged(newTarget, { heat, cool }) {
            this.currentEntry.tempMode = FixedSetpointUtil.convertToValue(newTarget, heat, cool);
        }

        _getTimePickerSection(hideAllDaySwitch) {
            var section = {
                    rows: []
                },
                isSwitchActive = this.currentEntry.from === 0 && this.currentEntry.to === 24 * 60;

            if (!hideAllDaySwitch) {
                section.rows.push({
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        icon: this.isIrcV2 ? null : Icon.Daytimer.CALENDAR,
                        title: _("controls.daytimer.entry.all-day"),
                        active: isSwitchActive
                    },
                    onSwitchChanged: function (value) {
                        this._handleAllDaySwitch(value);

                        this._createAndReloadTableContent();
                    }.bind(this)
                });
            }

            if (!isSwitchActive || hideAllDaySwitch) {
                section.rows.push(this._getTimePickerRow(true));
                section.rows.push(this._getTimePickerRow());
            }

            return section;
        }

        _getActivationRequiredSection(isIRCV2021) {
            var title = isIRCV2021 ? _('controls.ircv2021.activate-when-presence-detected') : _("controls.daytimer.activation-required"),
                leftIcon = isIRCV2021 ? null : Icon.Daytimer.LOCK.CLOSED;
            return {
                rows: [{
                    type: GUI.TableViewV2.CellType.SWITCH,
                    content: {
                        icon: leftIcon,
                        iconColor: window.Styles.colors.orange,
                        title: title,
                        active: this.currentEntry.needActivate
                    },
                    onSwitchChanged: function (value) {
                        // needsActivation
                        this.currentEntry.needActivate = value;
                    }.bind(this)
                }]
            };
        }

        _getAnalogValueSection() {
            return {
                rows: [{
                    type: GUI.TableViewV2.CellType.INPUT,
                    content: {
                        type: "text",
                        leftIconSrc: Icon.IRC.TEMPERATURE,
                        title: _("controls.daytimer.entry.value"),
                        value: this.currentEntry.value,
                        validationRegex: Regex.DECIMAL_VALUE
                    },
                    textChanged: function textChanged(section, row, tableView, value, valid) {
                        if (valid) {
                            value = value.replace(/,/, ".");
                            this.currentEntry.value = parseFloat(value);
                        }
                    }.bind(this)
                }]
            };
        }

        _getDeleteButtonSection() {
            return {
                rows: [{
                    type: GUI.TableViewV2.CellType.DELETE,
                    action: this._deleteEntry.bind(this)
                }]
            };
        }

        _getOperatingModeSection() {
            var modeString = '';

            for (var i = 0; i < this.priorityList.length; i++) {
                var modeNr = this.priorityList[i];

                if (this.currentEntry.equalModes.indexOf(modeNr) > -1) {
                    var name = shortenOperatingMode(modeNr);

                    if (modeString) {
                        modeString += ', ';
                    }

                    modeString += name;
                }
            }

            return {
                rows: [{
                    content: {
                        leftIconSrc: this.isIrcV2 ? null : Icon.Daytimer.CALENDAR,
                        title: _("controls.daytimer.modes.title.selection"),
                        disclosureIcon: true,
                        disclosureText: modeString
                    },
                    action: function () {
                        this.ViewController.showState(DaytimerControlEnums.ScreenState.OPERATING_MODES, null, {
                            control: this.control,
                            entry: this.currentEntry
                        });
                    }.bind(this)
                }]
            };
        }

        _getTimeForPicker(minutes) {
            var hourFrom = parseInt(minutes / 60);
            var minuteFrom = minutes % 60;
            return [hourFrom, minuteFrom];
        }

        _getTimePickerRow(isStartPicker) {
            var timePickerValue,
                cellTitle,
                pickerValue,
                disclosureText,
                leftIconSrc,
                cellClickable = true,
                maxTime = null;

            if (isStartPicker) {
                leftIconSrc = Icon.Daytimer.CLOCK;
                timePickerValue = this._getTimeForPicker(this.currentEntry.from);
                disclosureText = LxDate.formatSecondsIntoDigits(this.currentEntry.from * 60, true);
                cellTitle = _("controls.daytimer.entry.beginning");
            } else {
                leftIconSrc = Icon.Daytimer.CLOCK_END;
                timePickerValue = this._getTimeForPicker(this.currentEntry.to);
                disclosureText = LxDate.formatSecondsIntoDigits(this.currentEntry.to * 60, true);
                cellTitle = _("controls.daytimer.entry.end");
            }

            if (this.isPool && this.currentEntry.cycleMode === PoolControlEnums.PoolCycleMode.FLUSHING) {
                if (isStartPicker) {
                    maxTime = MILLISECONDS_OF_DAY - this.flushingTime * 60 * 1000; // maxTime is given in milliseconds
                } else {
                    disclosureText = LxDate.formatSecondsIntoDigits(this.currentEntry.to * 60, true);
                    leftIconSrc = Icon.Daytimer.CLOCK_END;
                    cellClickable = false;
                }
            }

            return {
                content: {
                    leftIconSrc: this.isIrcV2 ? null : leftIconSrc,
                    title: cellTitle,
                    disclosureText: disclosureText,
                    force24hours: true,
                    clickable: cellClickable,
                    disclosureColor: !isStartPicker && this.invalidToTime ? window.Styles.colors.red : null
                },
                action: function (cell, section, row, tableView) {
                    this._showContextMenu({
                        type: GUI.LxTimePickerContextMenu.TYPE.DURATION,
                        value: timePickerValue,
                        maxTime: maxTime,
                        wheelOrder: 'hhii',
                        onPickerDisappear: function (value) {
                            pickerValue = value[0] * 60 + value[1];

                            if (isStartPicker) {
                                this.currentEntry.from = pickerValue;
                            } else {
                                this.currentEntry.to = pickerValue;
                            } // special behaviour


                            if (this.isPool && this.currentEntry.cycleMode === PoolControlEnums.PoolCycleMode.FLUSHING) {
                                this.currentEntry.to = this.currentEntry.from + this.flushingTime;

                                this._checkAndAdoptEndTime();
                            } else {
                                this._checkUpdatedTimes(isStartPicker, section, row);

                            }
                            this._createAndReloadTableContent();

                        }.bind(this)
                    }, cellTitle, null, GUI.LxTimePickerContextMenu);
                }.bind(this)
            };
        }

        _checkUpdatedTimes(didChangeStart, section, row) {
            Debug.Control.Daytimer.EntryDetail && console.log("EntryDetail: _checkUpdatedTimes");

            if (this.currentEntry.from >= this.currentEntry.to) {
                this.invalidToTime = false;

                if (didChangeStart) {
                    this.currentEntry.to = this.currentEntry.from + this._getDefaultEntryDuration();

                    this._checkAndAdoptEndTime();
                } else {
                    this.invalidToTime = true;
                }
            } else if (this.invalidToTime) {
                this.invalidToTime = false;
            }
        }

        _checkAndAdoptEndTime() {
            if (this.currentEntry.to > MINUTES_OF_DAY) {
                this.currentEntry.to = MINUTES_OF_DAY;

                if (this.currentEntry.from >= this.currentEntry.to) {
                    this.invalidToTime = true;
                }
            } else {
                this.invalidToTime = false;
            }
        }

        _handleAllDaySwitch(allDay) {
            if (allDay) {
                this.currentEntry.from = 0;
                this.currentEntry.to = LxTimeDef.Minutes.MIDNIGHT;
            } else {
                SandboxComponent.getMiniserverTimeInfo(this, function (minutesSinceMidnight) {
                    this.currentEntry.from = this._findNextCycleFor(minutesSinceMidnight);
                    this.currentEntry.to = this.currentEntry.from + this._getDefaultEntryDuration();
                    this.currentEntry.to = Math.min(LxTimeDef.Minutes.MIDNIGHT, this.currentEntry.to);
                }.bind(this), TimeValueFormat.MINUTES_SINCE_MIDNIGHT);
            }
        }

        /**
         * Will add the current entry or update an existing one. Will dismiss the UI if successfull.
         * @returns {boolean}   whether or not the entry was added/updated
         * @private
         */
        _addNewEntry() {
            Debug.Control.Daytimer.EntryDetail && console.log("EntryDetail: _addNewEntry");
            var success = false;

            if (this.currentEntry.to <= this.currentEntry.from) {
                var content = {
                    title: _("error"),
                    message: _("controls.daytimer.time.mismatch.popup.message"),
                    buttonOk: true,
                    icon: Icon.ERROR,
                    color: window.Styles.colors.red
                };
                NavigationComp.showPopup(content);
            } else {
                success = true;

                this._sendAndDismiss();
            }

            return success;
        }

        _deleteEntry() {
            this.currentEntry.equalModes = [];

            this._sendAndDismiss();
        }

        _sendAndDismiss() {
            this._showUpdatingEntries();

            setTimeout(function () {
                this._sendAllEntries();

                this.ViewController.navigateBack();
            }.bind(this), 1000); // one second, otherwise iPad Mini won't show the popup.
        }

        _showUpdatingEntries() {
            if (this.showUpdatingPopup) {
                return;
            }

            var content = {
                title: _("controls.daytimer.updating-entries"),
                icon: Icon.INFO
            };
            this.showUpdatingPopup = NavigationComp.showPopup(content);
        }

        _removeUpdatingEntries() {
            if (this.showUpdatingPopup) {
                NavigationComp.removePopup(this.showUpdatingPopup);
                this.showUpdatingPopup = null;
            }
        }

        _sendAllEntries() {
            Debug.Control.Daytimer.EntryDetail && console.log("EntryDetail: _sendAllEntries");
            var i;

            if (this.isEditMode) {
                // remove all entries till before from all entries
                for (i = 0; i < this.entryBefore.equalModes.length; i++) {
                    var modeNrToCheck = this.entryBefore.equalModes[i];

                    if (this.allEntries.hasOwnProperty(modeNrToCheck)) {
                        var index = this._getIndexOfEntry(this.entryBefore, this.allEntries[modeNrToCheck]);

                        this.allEntries[modeNrToCheck].splice(index, 1); // removes entry at the found index
                    } else {
                        console.error("There are no entries for mode " + modeNrToCheck + "!");
                        return;
                    }
                }
            }

            this.currentEntry.equalModes.forEach(function (modeNr) {
                // and now add the new entries to all entries
                var singleEntry = cloneObjectDeep(this.currentEntry);
                singleEntry.mode = modeNr;

                if (!this.allEntries[modeNr] || singleEntry.from === 0 && singleEntry.to === 24 * 60 * 60) {
                    // create a new array of entries
                    this.allEntries[modeNr] = [singleEntry];
                } else {
                    // if all entries has already an entry in a mode, push the new one to the array
                    this._mergeEntryIntoMode(modeNr, singleEntry); // Filter out every obsolete entry


                    this.allEntries[modeNr] = this.allEntries[modeNr].filter(function (entry) {
                        return entry.to !== entry.from;
                    });
                }
            }.bind(this));
            var cmd = this.control.buildEntriesCommand(this.allEntries);
            var uuid = this.control.uuidAction;

            if (!this.isDayTimer) {
                uuid = this.control.uuidParent; // Wrike 48797388 - use uuidAction from Parent for IRC Daytimers!
            }

            this.sendCommand(cmd, null, uuid);
        }

        /**
         * Merges a given entry to the already existing entries
         * @param modeNr
         * @param newEntry
         * @private
         */
        _mergeEntryIntoMode(modeNr, newEntry) {
            var splittedEntry = null; // 1. Add the newEntry to the mode (operatingMode)

            this.allEntries[modeNr].push(newEntry); // 2. Sort the entries of the mode to allow merge in next step

            this.allEntries[modeNr].sort(function (l, r) {
                return l.from - r.from;
            }); // 3. Iterate over every entry and check if the previous or one
            //    of the following entries needs to be adopted (shortened, split, deleted and merge)

            this.allEntries[modeNr].forEach(function (entry, idx) {
                var prevEntry = this.allEntries[modeNr][idx - 1],
                    followingEntries = this.allEntries[modeNr].slice(idx + 1),
                    entryString = JSON.stringify(entry),
                    singleEntryString = JSON.stringify(newEntry); // Check, if this is the current entry

                if (entryString === singleEntryString) {
                    // The previous entry overlaps with the newly added entry
                    if (prevEntry && prevEntry.to > entry.from) {
                        // Check if the newly added entry is inside the previous entry
                        if (prevEntry.to > entry.to) {
                            // Split the entry and save it as "newEntry" to add it later
                            splittedEntry = cloneObject(prevEntry);
                            splittedEntry.from = entry.to;
                        } // Check if the previous and the current entry do have the same value


                        if (this._areValuesEqual(prevEntry, entry)) {
                            // Mark the previous one as "swallowed" to remove it and set the
                            // current entries start to the previous entry start
                            prevEntry.swallowed = true;
                            entry.from = prevEntry.from;
                        } else {
                            // Shorten the previous entry
                            prevEntry.to = entry.from;
                        }
                    }

                    if (followingEntries.length) {
                        // Iterate over every following entry
                        followingEntries.forEach(function (followingEntry) {
                            // One of the following entries is inside the newly added entry
                            if (followingEntry.from <= entry.to && followingEntry.to <= entry.to) {
                                // mark it as "swallowed" to remove it later
                                followingEntry.swallowed = true;
                            } else if (followingEntry.from <= entry.to) {
                                // Check if the following and the current entry do have the same value
                                if (this._areValuesEqual(followingEntry, entry)) {
                                    // Mark the following entry as "swallowed" to remove it and set the
                                    // current entries ends with the followingEntry entries end
                                    followingEntry.swallowed = true;
                                    entry.to = followingEntry.to;
                                } else {
                                    // Shorten the following entry
                                    followingEntry.from = entry.to;
                                }
                            }
                        }.bind(this));
                    }
                }
            }.bind(this)); // 4. Filter out every "swallowed" entry, they don't need to be sent to the miniserver

            this.allEntries[modeNr] = this.allEntries[modeNr].filter(function (entry) {
                return !entry.swallowed;
            }); // 5. Add the splitted up entry if available

            if (splittedEntry) {
                this._mergeEntryIntoMode(modeNr, splittedEntry);
            }
        }

        /**
         * Returns true, if both the value and the needActivate flags match between those two entries.
         * Important to know whether or not entires can be combined or must be split up.
         * @param entry1
         * @param entry2
         * @returns {boolean}
         * @private
         */
        _areValuesEqual(entry1, entry2) {
            var areEqual = this.control.getEntryValueString(entry1) === this.control.getEntryValueString(entry2);

            if (areEqual) {
                areEqual = !entry1.needActivate === !entry2.needActivate;
            }

            return areEqual;
        }

        _getIndexOfEntry(entry, allEntriesOfMode) {
            // entries of checked mode
            for (var j = 0; j < allEntriesOfMode.length; j++) {
                var entryToCheck = allEntriesOfMode[j]; // compare

                if (entry.from === entryToCheck.from && entry.to === entryToCheck.to) {
                    if (entry.value === entryToCheck.value && entry.needActivate === entryToCheck.needActivate) {
                        return j;
                    }
                }
            }
        }

        _updateToTimeOfCurrentEntry() {
            Debug.Control.Daytimer.EntryDetail && console.log("EntryDetail: _updateToTimeOfCurrentEntry");
            var timeDiff;

            if (this.isPool && this.currentEntry.cycleMode === PoolControlEnums.PoolCycleMode.FLUSHING) {
                this.currentEntry.to = this.currentEntry.from + this.flushingTime;

                if (this.currentEntry.to > MINUTES_OF_DAY) {
                    this.currentEntry.to = MINUTES_OF_DAY;
                    timeDiff = MINUTES_OF_DAY - this.currentEntry.from;
                    this.currentEntry.from = this.currentEntry.from - (this.flushingTime - timeDiff);
                }
            } else {
                this.currentEntry.to = this._findNextCycleFor(this.currentEntry.from + this._getDefaultEntryDuration());
            }

            this.currentEntry.to = Math.min(LxTimeDef.Minutes.MIDNIGHT, this.currentEntry.to);
        }

        _findNextCycleFor(minutesSinceMidnight) {
            var nextCycle = minutesSinceMidnight;
            var isIRC = this.isIrc || this.isIrcV2;
            var cycle = isIRC ? LxTimeDef.Minutes.IRC_CYCLE : LxTimeDef.Minutes.DAYTIMER_CYCLE;
            var cycleDiff = nextCycle % cycle;

            if (cycleDiff > 0) {
                nextCycle += cycle - cycleDiff;
            }

            return Math.min(LxTimeDef.Minutes.MIDNIGHT, nextCycle);
        }

        _getDefaultEntryDuration() {
            var isIRC = this.isIrc || this.isIrcV2;
            return isIRC ? LxTimeDef.Minutes.IRC_DEF_ENTRY : LxTimeDef.Minutes.DAYTIMER_DEF_ENTRY;
        }

    };
});
