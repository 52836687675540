import {View} from "react-native";
import {LxReactText} from "LxComponents";
import globalStyles from "GlobalStyles";
import {useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";

const baseStyles = {
    container: {
        marginVertical: globalStyles.spacings.gaps.bigger
    }
}

const ambientStyles = {
    ...baseStyles,
    timeLabel: {
        ...globalStyles.customStyles.ambientTextShadow,
        ...globalStyles.textStyles.ambientModeDate,
        color: globalStyles.colors.white
    },
    dateLabel: {
        ...globalStyles.customStyles.ambientTextShadow,
        ...globalStyles.textStyles.largeTitle.default,
        color: globalStyles.colors.white
    }
}

const ecoModeStyles = {
    ...baseStyles,
    timeLabel: {
        ...globalStyles.customStyles.ambientTextShadow,
        ...globalStyles.textStyles.ecoScreenDate,
        color: globalStyles.colors.white
    },
    dateLabel: {
        ...globalStyles.customStyles.ambientTextShadow,
        ...globalStyles.textStyles.pageHeading.default,
        color: globalStyles.colors.white
    }
}

function AmbientDateInfoView(props) {
    const dateSubscriptionRef = useRef(-1);
    const dateRef = useRef(null);
    const [dateChanged, setDateChanged] = useState(0);
    const styles = !!props.ecoModeActive ? ecoModeStyles : ambientStyles;

    const updateDate = (newDate) => {
        if (!dateRef.current || dateRef.current.minute() !== newDate.minute()) {
            dateRef.current = newDate;
            setDateChanged(getRandomIntInclusive(0, 99999999))
        }
    }

    const timeText = useMemo(() => {
        if (!dateRef.current) {
            return ""
        }
        let time = dateRef.current.format(LxDate.getDateFormat(DateType.TimeFormat));
        time = time.replace(" Uhr", "");

        return time;
    }, [dateChanged, dateRef.current])

    const dateText = useMemo(() => {
        if (!dateRef.current) {
            return ""
        }
        const formattedDate = dateRef.current.format(LxDate.getDateFormat(DateType.WeekdayAndDateTextNoYear));
        const dateWithDot = formattedDate.replace(/(\d{2})\s/, '$1. ');
        return dateWithDot;
    }, [dateChanged, dateRef.current])

    useEffect(() => {
        if (dateSubscriptionRef.current === -1) {
            dateSubscriptionRef.current = SandboxComponent.getMiniserverTimeInfo(this, updateDate.bind(this), TimeValueFormat.MINISERVER_DATE_TIME, 1000)
        }
        return () => {
            SandboxComponent.removeFromTimeInfo(dateSubscriptionRef.current)
            dateSubscriptionRef.current = -1;
        }
    }, [])

    return <View style={styles.container}>
        <LxReactText style={styles.timeLabel}>{timeText}</LxReactText>
        <LxReactText style={styles.dateLabel}>{dateText}</LxReactText>
    </View>
}

AmbientDateInfoView.propTypes = {
    ecoModeActive: PropTypes.bool
}

export default AmbientDateInfoView
