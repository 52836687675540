'use strict';

define(["AtheneControlContentBase", "AtheneVideoView", "AtheneControlEnums", "ControlInfoView", "AtheneMediaHandler"], function (AtheneControlContentBase, AtheneVideoView, AtheneEnums, ControlInfoView, AtheneMediaHandler) {
    return class AtheneControlContent extends AtheneControlContentBase {
        //region Getter
        get MAX_ACTIVITIES() {
            return 3;
        } //endregion Getter


        constructor(details) {
            super(...arguments);
        }

        supportedOrientation() {
            if (this._shouldShowVideo || HD_APP || AMBIENT_MODE) {
                // don't force portrait on HD Devices..
                return DeviceOrientation.ANY;
            } else {
                return DeviceOrientation.PORTRAIT_PRIMARY;
            }
        }

        viewDidLoad() {
            return AtheneMediaHandler.deviceSupportsH264().then(supportsH264 => {
                this._deviceSupportsH264 = supportsH264;
                return super.viewDidLoad(...arguments).then(() => {
                    this._applyAudioSettings();

                    this.settingsTableView.getElement().addClass("content__settings-table");
                });
            });
        }

        viewDidAppear() {
            return Q(super.viewDidAppear(...arguments) || true).then(function () {
                this.mediaHandler.readyPrms.then(function () {
                    this.processWhenVisible(function () {
                        if (this._urlComps && this._urlComps[0]) {
                            switch (this._urlComps[0]) {
                                case "tts":
                                    this._showTTSOptions();

                                    break;

                                case "call":
                                    this.startCall().finally(function () {
                                        this._answer();
                                    }.bind(this));
                                    break;

                                case "activities":
                                    NavigationComp.showState(AtheneEnums.Screens.ACTIVITIES, {
                                        control: this.control
                                    });
                                    break;
                            }
                        }

                        delete this._urlComps;
                    }.bind(this));
                }.bind(this));
                this.registerForOrientationChange();
            }.bind(this));
        }

        onOrientationChange(isLandscape) {
            this._updateCommandsTable(this.states);

            this._reloadSettingsTable(this.states);

            if (HD_APP && !this.isInAmbientMode()) {
                if (isLandscape) {
                    this.customInfoView && this.customInfoView.getElement().insertBefore(this.elements.content);
                } else {
                    this.customInfoView && this.customInfoView.getElement().insertAfter(this.elements.content);
                }
            }
        }

        getScreenButtons() {
            var screenButtons = super.getScreenButtons(...arguments);
            this._settingsButton = this._prepareButton(Icon.Athene.SETTINGS, "screen__button-right button-right__settings", function () {
                NavigationComp.showState(AtheneEnums.Screens.SETTINGS, {
                    control: this.control
                });
            }.bind(this));
            screenButtons.splice(1, 0, this._settingsButton);
            this.hideSubview(this._settingsButton);
            return screenButtons;
        }

        teardown() {
            this.stopCall();
            this.mediaHandler.stopRemoteVideoStream();
            this.customInfoView && this.customInfoView.updateVideoSource(null);
        }

        destroy() {
            this.teardown();
            return super.destroy(...arguments);
        }

        receivedStates(states) {
            let prevShouldShowVideo = this._shouldShowVideo;
            this._callState = states.callState;
            Q(super.receivedStates(...arguments)).then(() => {
                this.element.toggleClass("athene-control-content-base--no-vid", !this._shouldShowVideo);
                if (prevShouldShowVideo !== this._shouldShowVideo) {
                    this.ViewController.setOrientation(this.supportedOrientation());
                    this.setCustomInfoView(this.getCustomInfoView());
                }

                if (!this.bell) {
                    this._hideContextMenu();

                    this.customInfoView && this.customInfoView.hidePicture();
                } else if (this.control.isTrustMember && this._callState === AtheneMediaHandler.CALL_STATE.OCCUPIED && !this.mediaHandler.isActivelyCalling) {
                    this._answer(true, true);
                }

                this._handleSettingsButton();
            });
        }

        getCustomInfoView() {
            if (this._shouldShowVideo) {
                return new AtheneVideoView(this.control, {
                    deviceSupportsH264: this._deviceSupportsH264
                });
            } else {
                return null;
            }
        }

        /**
         * If we are on HD and the control has videoInfo we prepend the customInfoView
         * to be able to display it fullscreen
         * @param view
         */
        setCustomInfoView(view) {
            var prms = Q.resolve();

            if (this.customInfoView) {
                prms = this.removeSubview(this.customInfoView).then(() => {
                    this.customInfoView.getElement && this.customInfoView.getElement().remove();
                    this.customInfoView = null;
                    return Q.resolve();
                }).catch((e) => {
                    return Q.reject(`View this.customInfoView (${this.customInfoView}) already got destroyed ${e}`);
                });
            }

            prms.then(function () {
                return GUI.animationHandler.schedule(function prepareAtheneHDInfoView() {
                    let prms = [];
                    this.elements.customInfoBox.empty();
                    this.customInfoView = view;

                    if (view) {
                        this.customInfoView.getElement().addClass("athene-control-content__video-view");
                        this.elements.customInfoBox.css("display", "");

                        if (HD_APP && !this.isInAmbientMode()) {
                            this.addToHandledSubviews(this.customInfoView);
                            this.elements.customInfoBox.hide();
                            if (this.isLandscape) {
                                this.customInfoView.getElement().insertBefore(this.elements.content);
                            } else {
                                this.customInfoView.getElement().insertAfter(this.elements.content);
                            }
                        } else {
                            prms = this.appendSubview(this.customInfoView, this.elements.customInfoBox);
                        }

                        return Q.all(prms);
                    }
                }.bind(this));
            }.bind(this));
        }

        shouldDisplayStateIcons() {
            return false;
        }

        startCall() {
            if (this._shouldShowVideo) {
                return this.mediaHandler.readyPrms.then(function () {
                    return this.customInfoView.playVideo(true).finally(function () {
                        this.customInfoView.hidePicture();
                        return this.mediaHandler.startAudio();
                    }.bind(this));
                }.bind(this));
            } else {
                return Q.reject("Intercom is in Trust, can't start the Video");
            }
        }

        stopCall() {
            this.mediaHandler.muteLocalAudio(false);

            if (!this.remoteVideoEnabled) {
                this.customInfoView && this.customInfoView.hideBlocking();
                this.remoteVideoEnabled = true;
                this.customInfoView && this.customInfoView.playVideo(this.remoteVideoEnabled);
            }

            this.mediaHandler.stopAudio();
        }

        toggleRemoteAudio() {
            if (this.mediaHandler.isActivelyCalling) {
                // This device is currently in a call
                this.customInfoView && this.customInfoView.hideTime();
                this.stopCall();
            } else if (this._callState === AtheneMediaHandler.CALL_STATE.AVAILABLE && !this.mediaHandler.isActivelyCalling) {
                this.startCall().finally(function () {
                    this._answer();
                }.bind(this));
            } else {
                NavigationComp.showPopup({
                    title: _("athene.audio.occupied.title"),
                    message: _("athene.audio.occupied.message"),
                    buttonOk: true
                });
            }

            this.receivedStates(this.states);
        }

        toggleRemoteVideo() {
            this.remoteVideoEnabled = !this.remoteVideoEnabled;
            this.customInfoView && this.customInfoView.playVideo(this.remoteVideoEnabled);
            this.receivedStates(this.states);
        }

        // ###################################################
        // ############### Action table view #################
        // ###################################################
        getActionTableView() {
            if (HD_APP && !this.isInAmbientMode()) {
                return new GUI.CenteredCardView(this.tableViewDataSource, this.tableViewDelegate);
            } else {
                return new GUI.CardView(this.tableViewDataSource, this.tableViewDelegate);
            }
        }

        getActionTableViewCellType() {
            return GUI.TableViewV2.CellType.Special.INTERCOM_ACTION_CELL;
        }

        getTableContent(states) {
            if (states.universalIsLocked) {
                return [];
            }

            let rows = [];

            if (this._shouldShowVideo) {
                rows.pushObject(this._getRemoteAudioRow());

                this._getBellActiveRows().forEach(function (row) {
                    rows.pushObject(row);
                }); // Removed until further notice
                //rows.pushObject(this._getRemoteVideoRow());


                this._getCallActiveRows().forEach(function (row) {
                    rows.pushObject(row);
                });

                rows.pushObject(this._getActivitiesRow());

                this._generateLandscapeRows().forEach(function (row) {
                    rows.pushObject(row);
                });

                return [{
                    rows: rows
                }];
            } else {
                return this.mediaHandler.getLastActivities({
                    from: 0,
                    to: this.MAX_ACTIVITIES
                }).then(activities => {
                    rows = activities.map((activity, idx) => {
                        return {
                            content: {
                                activity: activity,
                                disabled: this.mediaHandler.useNewAuthMethod ? false : !this.states.comReady,
                                inContent: true
                            },
                            type: GUI.TableViewV2.CellType.Special.ACTIVITY,
                            action: () => {
                                NavigationComp.showState(AtheneEnums.Screens.ACTIVITY_DETAILS, {
                                    control: this.control,
                                    selectedIdx: idx
                                });
                            }
                        };
                    });

                    if (rows.length >= this.MAX_ACTIVITIES) {
                        rows.push({
                            content: {
                                disabled: this.mediaHandler.useNewAuthMethod ? false : !this.states.comReady,
                                inContent: true
                            },
                            type: GUI.TableViewV2.CellType.Special.ACTIVITY,
                            action: () => {
                                NavigationComp.showState(AtheneEnums.Screens.ACTIVITIES, {
                                    control: this.control
                                });
                            }
                        });
                    }

                    return [{
                        headerTitle: _("controls.intercom.last-activity_plural"),
                        customClass: "card-section",
                        rows: rows
                    }, {
                        rows: this._generateLandscapeRows()
                    }];
                });
            }
        }

        // ###################################################
        // ######### AtheneAtheneMediaHandler Delegates ############
        // ###################################################
        onRemoteStreamStateChanged() {
            var streamState = this.mediaHandler.remoteStreamState;

            if (streamState === AtheneMediaHandler.REMOTE_STREAM_STATE.ERROR) {
                this._handleRemoteStreamError();

                this.control && this.receivedStates(this.states);
            }
        }

        onLocalStreamStateChanged() {
            var streamState = this.mediaHandler.localStreamState;

            switch (streamState) {
                case AtheneMediaHandler.LOCAL_STREAM_STATE.ERROR:
                    // No Mic available, handle the error!
                    this._handleLocalStreamError();

                    this.customInfoView && this.customInfoView.hideTime();
                    this.stopCall();
                    break;

                case AtheneMediaHandler.LOCAL_STREAM_STATE.IDLE:
                    this.customInfoView && this.customInfoView.hideTime();
                    this.stopCall();
                    break;

                case AtheneMediaHandler.LOCAL_STREAM_STATE.ESTABLISHING:
                    this.customInfoView && this.customInfoView.showTime(true);
                    break;

                case AtheneMediaHandler.LOCAL_STREAM_STATE.ESTABLISHED:
                    this.customInfoView && this.customInfoView.showTime();
                    break;

                default:
                    break;
            }

            this.control && this.receivedStates(this.states);
        }

        onComError(e) {
            this.teardown();
            super.onComError(...arguments);
        }

        onExcessiveTemp(toHot) {
            if (!toHot && this.customInfoView) {
                this.customInfoView.hideBlocking();
                return this.customInfoView.playVideo(true);
            }
        }

        closeAction() {
            this._answer(false, true);

            return super.closeAction(...arguments);
        }

        dismissAlert() {
            this._answer(false, true);

            return super.dismissAlert(...arguments);
        }

        // ###################################################
        // ################# Private #########################
        // ###################################################
        _getSettingsTableContent(states) {
            let tableContent = super._getSettingsTableContent(...arguments);

            if (!states.universalIsLocked) {
                tableContent.splice(0, 0, this.getActionSection());
            }

            if (!this._shouldShowVideo && tableContent.length) {
                tableContent[0].rows.unshift(this._getActivitiesRow());
            }

            return tableContent;
        }

        _generateLandscapeRows() {
            var rows = [],
                activityRows = this.getActionSection().rows;

            if (this.isLandscape && !this.isInAmbientMode()) {
                rows.push(this._getMuteRow(GUI.TableViewV2.CellType.Special.INTERCOM_ACTION_CELL));

                if (activityRows && activityRows.length) {
                    if (HD_APP && !this.isInAmbientMode()) {
                        rows = rows.concat(activityRows);
                    } else {
                        if (activityRows.length === 1) {
                            rows = rows.concat(activityRows);
                        } else {
                            rows.push({
                                content: {
                                    icon: Icon.Buttons.MORE_HORIZONTAL,
                                    title: _("actions"),
                                    clickable: true,
                                    fillColor: ColorLight.APP_BACKGROUND_TERTIARY_B,
                                    color: Color.WHITE
                                },
                                action: function () {
                                    this._showContextMenu(activityRows.map(function (row, index) {
                                        return {
                                            title: row.content.title,
                                            action: row.action
                                        };
                                    }.bind(this)));
                                }.bind(this)
                            });
                        }
                    }
                }
            }

            return rows;
        }

        _getBellActiveRows(rows) {
            if (this._shouldShowVideo && this.bell && !this.mediaHandler.isActivelyCalling) {
                return [this._getIgnoreRow(), this._getTtsRow()];
            }

            return [];
        }

        _applyAudioSettings() {
            var inputDeviceId = PersistenceComponent.getLocalStorageItem(this._createKey(AtheneEnums.SETTINGS.INPUT_DEVICE));
            this.features && this.features.canSetMicrophone && inputDeviceId && this._setLocalDeviceFromSetting(AtheneMediaHandler.DEVICE_KIND.AUDIO.INPUT, inputDeviceId);
        }

        _setLocalDeviceFromSetting(deviceKind, deviceId) {
            var devices, foundDevice;

            if (deviceKind === AtheneMediaHandler.DEVICE_KIND.AUDIO.INPUT) {
                devices = this.mediaHandler.localInputDevices;
            } else if (deviceKind === AtheneMediaHandler.DEVICE_KIND.AUDIO.OUTPUT) {
                devices = this.mediaHandler.localOutputDevices;
            }

            foundDevice = devices.find(function (device) {
                return device.deviceId === deviceId;
            });

            if (deviceKind === AtheneMediaHandler.DEVICE_KIND.AUDIO.INPUT) {
                foundDevice && (this.mediaHandler.localInputDevice = foundDevice);
            } else if (deviceKind === AtheneMediaHandler.DEVICE_KIND.AUDIO.OUTPUT) {
                foundDevice && (this.mediaHandler.localOutputDevice = foundDevice);
            }
        }

        _handleRemoteStreamError() {
            var error = this.mediaHandler.remoteStreamError,
                removeVideo = true;

            if (error) {
                switch (error.id) {
                    case AtheneMediaHandler.REMOTE_STREAM_ERROR.SEND_SDP_OFFER:
                        removeVideo = this._handleSDPOfferError(error);
                        break;

                    case AtheneMediaHandler.REMOTE_STREAM_ERROR.KICKED:
                        switch (error.info.type) {
                            case AtheneMediaHandler.KICK_TYPE.CALL_RESPONSE:
                                this.customInfoView && this.customInfoView.showBlocking(Icon.Athene.NO_VIDEO, _("athene.video.kicked-by", {
                                    user: error.info.info
                                }));
                                break;

                            case AtheneMediaHandler.KICK_TYPE.RE_RING:
                                removeVideo = false;
                                this.stopCall();
                                break;

                            default:
                                removeVideo = false;
                        }

                        break;

                    case AtheneMediaHandler.REMOTE_STREAM_ERROR.NO_ICE_MATCH_FOUND:
                        this.customInfoView && this.customInfoView.showBlocking(Icon.Athene.NO_VIDEO, _("clouddns.connection-failed"));
                        break;

                    case AtheneMediaHandler.REMOTE_STREAM_ERROR.EXCESSIVE_TEMP:
                        this.customInfoView && this.customInfoView.showBlocking(Icon.Athene.NO_VIDEO, _("athene.video.excessive-temp"));
                        break;

                    default:
                        this.customInfoView && this.customInfoView.showBlocking(null, _("athene.video-error"));
                }
            } else {
                this.customInfoView && this.customInfoView.showBlocking(null, _("athene.video-error"));
            }

            removeVideo && this.customInfoView && this.customInfoView.updateVideoSource(null);
        }

        _handleSDPOfferError(error) {
            var removeVideo = true;

            if (error.hasOwnProperty("info")) {
                switch (error.info.code) {
                    case AtheneMediaHandler.OFFER_ERROR.NUMBER_OF_VIDEO_STREAMS_EXCEEDED:
                        this.customInfoView && this.customInfoView.showBlocking(Icon.Athene.NO_VIDEO, _("athene.video.quota-exceeded", {
                            max: this.mediaHandler.deviceInfo.videoStreamQuota
                        }));
                        break;

                    case AtheneMediaHandler.OFFER_ERROR.CALL_ACTIVE_IN_OTHER_SESSION:
                        removeVideo = false;
                        NavigationComp.showPopup({
                            title: _("athene.audio.occupied.title"),
                            message: _("athene.audio.occupied.message"),
                            buttonOk: true
                        });
                        break;

                    case AtheneMediaHandler.OFFER_ERROR.METHOD_NOT_ALLOWED:
                        // Do nothing as this is already handled (retry) in the AtheneMediaHandler
                        break;

                    default:
                        this.customInfoView && this.customInfoView.showBlocking(null, _("athene.video-error"));
                }
            } else {
                this.customInfoView && this.customInfoView.showBlocking(null, _("athene.video-error"));
            }

            return removeVideo;
        }

        _handleLocalStreamError() {
            var error = this.mediaHandler.localStreamError,
                title,
                message;

            if (error.info.name === "NotReadableError" || error.info.name === "TrackStartError" || error.info.name === "NotAllowedError" || error.info.name === "PermissionDeniedError") {
                // permission denied in browser
                console.log(this.name, 'Permission Denied.');
                title = _("controls.intercom.voice.missing-mic-permissions.title");
                message = _("controls.intercom.voice.missing-mic-permissions.explanation");
            } else if (error.info.name === "NotFoundError") {
                // No mic available (e.g. MacMini)
                console.log(this.name, 'No Mic found.');
                title = _("controls.intercom.voice.missing-mic-device.title");
                message = _("controls.intercom.voice.missing-mic-device.explanation");
            } else {
                // other errors
                console.log(this.name, 'Sorry! Another error occurred.');
                message = _("controls.intercom.voice.error.explanation", {
                    error: error.info.name,
                    code: error.info.code
                });
            }

            if (message) {
                if (PlatformComponent.isCordova || PlatformComponent.isMacOS()) {
                    NavigationComp.showPopup({
                        title: title,
                        message: message,
                        color: window.Styles.colors.orange,
                        buttonOk: _("settings"),
                        buttonCancel: true
                    }).then(function () {
                        if (PlatformComponent.isCordova) {
                            window.cordova.plugins.settings.open(["application_details", true]);
                        } else {
                            electron.shell.openExternal("x-apple.systempreferences:com.apple.preference.security?Privacy_Microphone");
                        }
                    });
                } else {
                    NavigationComp.showPopup({
                        title: title,
                        message: message,
                        color: window.Styles.colors.orange,
                        buttonOk: true
                    });
                }
            }
        }

        // ###################################################
        // ############# Settings table view #################
        // ###################################################
        _getRemoteAudioRow() {
            var isOccupied = this._callState === AtheneMediaHandler.CALL_STATE.OCCUPIED,
                isActivelyCalling = this.mediaHandler.isActivelyCalling,
                isRemoteVideoPending = this.mediaHandler.isRemoteVideoPending,
                title = isActivelyCalling ? _("athene.end-calling") : _("athene.start-calling"),
                icon,
                color,
                additionalClass,
                disabled = this.states.videoStreamQuotaExeeded;

            if(isRemoteVideoPending) {
                title = _("controls.intercom.video.establish-connection");
            }
            if (isOccupied && !isActivelyCalling) {
                title = _("controls.intercom.voice.connection-busy");
                icon = Icon.Athene.OCCUPIED;
                color = "#808080"; // color according to Girli

                additionalClass = "disabled--user-interaction";
            } else if (isActivelyCalling) {
                if (this.mediaHandler.localStreamState === AtheneMediaHandler.LOCAL_STREAM_STATE.ESTABLISHING) {
                    icon = Icon.INDICATOR;
                    color = window.Styles.colors.red;
                    disabled = true;
                } else {
                    icon = Icon.Athene.HANG_UP;
                    color = window.Styles.colors.red;
                }
            } else if (this.mediaHandler.remoteStreamState === AtheneMediaHandler.REMOTE_STREAM_STATE.VIDEO_PENDING) {
                icon = Icon.INDICATOR;
                color = window.Styles.colors.green;
                disabled = true;
            } else {
                icon = Icon.Athene.PICK_UP;
                color = window.Styles.colors.green;
            }

            return {
                content: {
                    title: title,
                    icon: icon,
                    fillColor: Color.WHITE,
                    color: color,
                    class: additionalClass,
                    disabled: disabled
                },
                action: this.toggleRemoteAudio.bind(this)
            };
        }

        _getRemoteVideoRow() {
            if (this.mediaHandler.isActivelyCalling && this.mediaHandler.remoteVideoSource) {
                var row = {
                    content: {
                        title: _("controls.intercom.about.video"),
                        icon: Icon.Athene.NO_VIDEO,
                        color: this.remoteVideoEnabled ? Color.STATE_INACTIVE : Color.WHITE,
                        fillColor: this.remoteVideoEnabled ? Color.WHITE : window.Styles.colors.red
                    },
                    action: this.toggleRemoteVideo.bind(this)
                };

                if (this.isLandscape && !this.isInAmbientMode()) {
                    row.content.color = this.remoteVideoEnabled ? ColorLight.APP_BACKGROUND_TERTIARY_B : Color.WHITE;
                    row.content.fillColor = this.remoteVideoEnabled ? Color.WHITE : window.Styles.colors.red;
                }

                return row;
            }

            return null;
        }

        _getCallActiveRows() {
            var rows = [];

            if (this.mediaHandler.isActivelyCalling) {
                if (this.mediaHandler.localAudioMuted) {
                    rows.push(this._getStartLocalStreamRow());
                } else {
                    rows.push(this._getStopLocalStreamRow());
                }
            }

            return rows;
        }

        _getStartLocalStreamRow() {
            return {
                content: {
                    title: _("controls.intercom.settings.mic"),
                    icon: Icon.Athene.NO_MIC,
                    color: Color.WHITE,
                    fillColor: window.Styles.colors.red
                },
                action: function () {
                    this.mediaHandler.muteLocalAudio(!this.mediaHandler.localAudioMuted);

                    this._updateCommandsTable(this.states);
                }.bind(this)
            };
        }

        _getStopLocalStreamRow() {
            return {
                content: {
                    title: _("controls.intercom.settings.mic"),
                    icon: Icon.Athene.MIC,
                    color: Color.WHITE,
                    fillColor: Color.APP_BACKGROUND_TERTIARY_B
                },
                action: function () {
                    this.mediaHandler.muteLocalAudio(!this.mediaHandler.localAudioMuted);

                    this._updateCommandsTable(this.states);
                }.bind(this)
            };
        }

        _handleSettingsButton() {
            if (HD_APP && !this.isInAmbientMode() && !(this.states && this.states.universalIsLocked)) {
                var amountVisibleButtons = this.element.find(".screen__button-right:visible:not(.button-right__settings)").length,
                    right; // use screenButton [1] because [0] is the close button with different width!

                right = amountVisibleButtons * this.screenButtons[1].element.width();

                this._settingsButton.element.css("right", right);

                this.showSubview(this._settingsButton);
            } else {
                this.hideSubview(this._settingsButton);
            }
        }

    };
});
