'use strict';

window.GUI = function (GUI) {

    class AudioZoneV2ControlContentViewControllerReactAbstractor extends GUI.ViewController {
        constructor(details) {
            super($('<div/>'));
        }

        getAnimation() {
            return AnimationType.FADE;
        }
    }

    GUI.AudioZoneV2ControlContentViewControllerReactAbstractor = AudioZoneV2ControlContentViewControllerReactAbstractor;

    class AudioZoneV2ControlContentViewController extends GUI.ControlContentViewController {
        //region Static
        static shouldHandleInControlContentVc(identifier) {
            var shouldHandle = false;

            switch (identifier) {
                case Controls.AudioZoneV2Control.Enums.ScreenState.SELECTOR_WRAPPER:
                case Controls.AudioZoneV2Control.Enums.ScreenState.SETTINGS_SHORTCUT_EDIT:
                case Controls.AudioZoneV2Control.Enums.ScreenState.CONTEXT_MENU_BASE:
                case Controls.AudioZoneV2Control.Enums.ScreenState.ADD_MEDIA_VC_BASE:
                case Controls.AudioZoneV2Control.Enums.ScreenState.ADD_MEDIA_VC_PLAYLIST:
                case Controls.AudioZoneV2Control.Enums.ScreenState.ADD_MEDIA_VC_PLAYLIST_PICKER:
                case Controls.AudioZoneV2Control.Enums.ScreenState.ADD_MEDIA_VC_PLAYLIST_SPOTIFY:
                case Controls.AudioZoneV2Control.Enums.ScreenState.ADD_MEDIA_VC_SPOTIFY_ONBOARDING:
                case Controls.AudioZoneV2Control.Enums.ScreenState.ADD_MEDIA_VC_FAVS:
                case Controls.AudioZoneV2Control.Enums.ScreenState.NEW_SPOTIFY_ACCOUNT_SCREEN:
                case Controls.AudioZoneV2Control.Enums.ScreenState.SPOTIFY_ONBOARDING:
                case Controls.AudioZoneV2Control.Enums.ScreenState.EDIT_MEDIA:
                case Controls.AudioZoneV2Control.Enums.ScreenState.SETTINGS.SCREEN:
                case Controls.AudioZoneV2Control.Enums.ScreenState.SETTINGS_SERVICE_LOGIN:
                case Controls.AudioZoneV2Control.Enums.ScreenState.SETTINGS_SERVICES_SOUNDSUIT_MANAGEMENT:
                case Controls.AudioZoneV2Control.Enums.ScreenState.SETTINGS_SERVICES_SOUNDSUIT_ACCOUNT:
                case Controls.AudioZoneV2Control.Enums.ScreenState.SETTINGS_LIBRARY_ADD_OR_EDIT:
                case Controls.AudioZoneV2Control.Enums.ScreenState.GROUP_OVERVIEW:
                case Controls.AudioZoneV2Control.Enums.ScreenState.VOICE_RECORDER:
                case Controls.AudioZoneV2Control.Enums.ScreenState.CUSTOM_STREAM_SCREEN:
                case Controls.AudioZoneV2Control.Enums.ScreenState.EDIT_SPECIFIC_FAV:
                case Controls.AudioZoneV2Control.Enums.ScreenState.SETTINGS.ABOUT:
                case ScreenState.ControlLocking:
                case ScreenState.MessageCenterMessageScreen:
                    shouldHandle = true;
                    break;
            }

            return shouldHandle;
        } //endregion Static


        constructor(details) {
            super(details);
            this.controlUuidAction = this.control.uuidAction;

            if (details && details.contentDetails && details.contentDetails.item) {
                this._cachedStartItem = details.contentDetails.item;
            }
        }

        destroy() {
            this.setAudioViewsVisible(false);
            return super.destroy(...arguments);
        }

        viewWillAppear() {
            if (Debug.Communication) {
                window.AZv2CCVCAppearTiming = timingNow();
            }
            // ensure that e.g. after the app was in the background, the audioViewsVisible is reactivated again!
            if (this._isInHistory(this._viewIdentifier)) {
                this.setAudioViewsVisible(true);
            }
            return super.viewWillAppear(...arguments);
        }

        updateView(details) {
            if (details && details.contentDetails && details.contentDetails.item) {
                this.navigateToItem(details.contentDetails.item);
            }

            return super.updateView(...arguments);
        }

        /**
         * As long as the audioserver cannot play any music, the not-ready-screen is to be shown.
         * @returns {[string]}
         */
        getStateIDsForViewDetection() {
            var stateIds = super.getStateIDsForViewDetection(...arguments);
            stateIds.push("canPlayMusic");
            return stateIds;
        }

        /**
         * Will open up a screen showing the content of the item provided.
         * If a viewcontroller is provided, it will open it up within that.
         * If no viewController is provided, it'll show it in here.
         * @param item      the item of which the content is to be shown
         * @param [vc]      the viewController to be used for showing this item.
         * @returns {Q.Promise<unknown>}
         */
        navigateToItem(item, vc) {
            console.log(this.viewId, "navigateToItem: ", item);
            var def = Q.defer(),
                clonedItem = cloneObject(item),
                user,
                customizationType,
                mediaType,
                identifier,
                details,
                showDetailedViewCntrl = false; // if a viewController is provided, check if a detailedContentViewController needs to be shown first.

            if (!!vc) {
                // this is crucial as otherwise the items content is shown without context menu options.
                showDetailedViewCntrl = !(vc instanceof GUI.AudioZoneV2ControlDetailedContentViewController);
            }

            delete this._cachedStartItem;

            let SpotifyAccountManager = require ("SpotifyAccountManager");

            var prepareDef = Q.defer();

            switch (clonedItem.contentType) {
                case MusicServerEnum.MediaContentType.SERVICE:
                    SpotifyAccountManager.shared(this.control).prepare().done(function () {
                        user = SpotifyAccountManager.getUserUnique(SpotifyAccountManager.shared(this.control).activeUser);
                        customizationType = MusicServerEnum.ControlContentMenuId.SPOTIFY;
                        mediaType = MusicServerEnum.MediaType.PLAYLIST;
                        identifier = MusicServerEnum.Service.SPOTIFY;
                        prepareDef.resolve();
                    }.bind(this));
                    break;

                case MusicServerEnum.MediaContentType.LIBRARY:
                case MusicServerEnum.MediaContentType.PLAYLISTS: // local playlists opened via queue have a different contentType
                    customizationType = MusicServerEnum.ControlContentMenuId.LIB;
                    mediaType = MusicServerEnum.MediaType.LIBRARY;
                    identifier = MusicServerEnum.Service.LMS;
                    prepareDef.resolve();
                    break;

                default:
                    prepareDef.resolve();
                    break;
            }

            return prepareDef.promise.then(function () {
                details = {
                    username: user,
                    lastSelectedItem: clonedItem,
                    identifier: identifier,
                    contentTypes: [clonedItem.contentType],
                    mediaType: mediaType,
                    control: this.control,
                    customizationKey: customizationType
                }; // if shown inside here, navigate back first - otherwise proceed.

                return (!!vc ? Q.resolve() : this.navigateBackToRoot()).then(function () {
                    if (showDetailedViewCntrl) {
                        return def.resolve(vc.showState(ScreenState.AudioZoneV2DetailedContentVC, null, {
                            showStateArgs: [Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(clonedItem), null, details],
                            control: this.control
                        }, HD_APP ? AnimationType.MODAL : AnimationType.FADE));
                    } else {
                        return def.resolve((vc || this).showState(Controls.AudioZoneV2Control.MediaBrowserV2Base.getScreenStateForItem(clonedItem), null, details));
                    }
                }.bind(this));
            }.bind(this));
        }

        _showControlContent() {
            var states = this.control.getStates(),
                canPlay = states.canPlayMusic;

            if (canPlay) {
                return super._showControlContent(...arguments);
            } else {
                return this._showIfNotVisibleAlready("AudioZoneV2NotReadyScreen", {
                    control: this.control
                }, AnimationType.FADE);
            }
        }

        _darkenView(viewToDarken, onClick, onLeftSwipe) {
            if (AMBIENT_MODE && this.miniPlayer && this._darkenedViews.length === 0) {
                super._darkenView(this.miniPlayer.getElement());
            }
            super._darkenView(...arguments);
        }

        _shouldHandleTinyPlayer(affectedView) {
            return affectedView.find(".audio-zone-v2-control-content").length > 0;
        }

        _lightenView(darkenedView) {
            super._lightenView(...arguments);
            if (AMBIENT_MODE && this.miniPlayer && this._darkenedViews.length === 1) {
                super._lightenView(this.miniPlayer.getElement());
            }
        }

        updateURL() {
            NavigationComp.updateURL(window.CURRENT_URL.split("/")[0] + "/" + this.getURL())
        }

        handleStateShown(screenState) {
            this.updateURL();
            if (screenState === this._viewIdentifier && !this.miniPlayer) {
                this.setAudioViewsVisible(true);
                this.miniPlayer = new GUI.AudioZoneV2ControlMiniPlayerViewController(this.control);
                this.miniPlayer.setViewController(this);
                this.prependSubview(this.miniPlayer);
            } else if (this.miniPlayer) {
                this.setAudioViewsVisible(false);
                this.removeSubview(this.miniPlayer);
                this.miniPlayer = null;
            }

            this.element && this.element.toggleClass("audio-zonev2-control-content-view-controller--with-tiny-player", !!this.miniPlayer);
            return super.handleStateShown(...arguments);
        }

        setAudioViewsVisible(isVisible) {
            if (isVisible) {
                // avoid duplicate calls, as the viewWillAppear will also activate it  (aside handleStateShown)
                if (!this.control.audioserverComp.getAudioViewsVisible(this.control) || this.control.audioserverComp.ViewController !== this) {
                    console.log(this.viewId, "setAudioViewsVisible: true"); //developerAttention(this.viewId + " setAudioViewsVisible: true", window.Styles.colors.green);

                    this.control.audioserverComp.setAudioViewsVisible(true, this.control, this);
                } else {
                    console.log(this.viewId, "setAudioViewsVisible: true - BUT already set to visible!!"); //developerAttention(this.viewId + " setAudioViewsVisible: false", window.Styles.colors.orange);
                }
            } else {
                console.log(this.viewId, "setAudioViewsVisible: false"); //developerAttention(this.viewId + " setAudioViewsVisible: false", window.Styles.colors.red);

                this.control.audioserverComp.setAudioViewsVisible(false, this.control, this);
            }
        }

    }

    GUI.AudioZoneV2ControlContentViewController = AudioZoneV2ControlContentViewController;
    return GUI;
}(window.GUI || {});
