'use strict';

define(['AudioZoneImageCache'], function ({ getCachedImageBlob }) {
    class CoverView extends GUI.View {
        //region Static
        static Template = class {
            //region Static
            static getEmptyElement() {
                return $('<div class="cover-placeholder cover-placeholder--default">' + '   ' + ImageBox.getResourceImageWithClasses(Icon.EMPTY, 'cover-placeholder__icon') + '</div>');
            } //endregion Static


        }; //endregion Static

        constructor(elm, initIconObj, showThumb) {
            super($(elm));
            applyMixins(this, StateHandler.Mixin);
            this._previousIconObj = initIconObj;
            this._currIconHash = JSON.stringify(this._previousIconObj).hashCode();
            this._showThumb = showThumb;
        }

        viewDidLoad() {
            Debug.Control.AudioZone.CoverView && console.log(this.viewId, "viewDidLoad");
            return super.viewDidLoad(...arguments).then(function () {
                this.coverElement = CoverView.Template.getEmptyElement(this._previousIconObj, this._showThumb);

                if (this._previousIconObj.hasProvidedCover) {
                    getCachedImageBlob(this._previousIconObj.highRes || this._previousIconObj.lowRes).then((blob) => {
                        this._loadImagesAndShow({ ...this._previousIconObj, highRes: blob, lowRes: blob }, this._currIconHash);
                    }).catch(err => {
                        console.error(err);
                        this._loadImagesAndShow(this._previousIconObj, this._currIconHash);
                    });
                } else {
                    this._loadImagesAndShow(this._previousIconObj, this._currIconHash);
                }

                return GUI.animationHandler.append(this.coverElement, this.element);
            }.bind(this));
        }

        setIconObject(iconObj) {
            var newHash = JSON.stringify(iconObj).hashCode(); // Only update the cover if the iconObj did change!

            if (this._currIconHash !== newHash) {
                Debug.Control.AudioZone.CoverView && console.log(this.viewId, "setIconObj: ", iconObj);
                this._previousIconObj = iconObj;
                this._currIconHash = newHash;

                if (this._previousIconObj.hasProvidedCover) {
                    getCachedImageBlob(this._previousIconObj.highRes || this._previousIconObj.lowRes).then((blob) => {
                        this._loadImagesAndShow({ ...this._previousIconObj, highRes: blob, lowRes: blob }, this._currIconHash);
                    }).catch(err => {
                        console.error(err);
                        this._loadImagesAndShow(this._previousIconObj, this._currIconHash);
                    });
                } else {
                    this._loadImagesAndShow(this._previousIconObj, this._currIconHash);
                }

                return Q.resolve();
            } else {
                return Q.resolve();
            }
        }

        destroy() {
            this._currIconHash = -1;
            return super.destroy(...arguments);
        }

        empty() {
            var promise;

            if (this.coverElement) {
                this._currIconHash = -1;
                delete this._previousIconObj;
                var oldCover = this.coverElement;
                this.coverElement = CoverView.Template.getEmptyElement();
                promise = GUI.animationHandler.replace(oldCover, this.coverElement);
            } else {
                promise = Q.resolve();
            }

            return promise;
        }

        _loadImagesAndShow(iconObj, hash) {
            var promise;
            Debug.Control.AudioZone.CoverView && console.log(this.viewId, "_loadImagesAndShow: hasProvidedCover=" + iconObj.hasProvidedCover);
            var defaultIconSrc = iconObj.fallback || Icon.AudioZone.NEW.NOTE;
            var coverIconSrc = Debug.Test.NO_COVERS ? null : this._showThumb ? iconObj.lowRes : iconObj.highRes;

            if (!iconObj.hasProvidedCover) {
                defaultIconSrc = (this._showThumb ? iconObj.lowRes : iconObj.highRes) || defaultIconSrc;
                coverIconSrc = null;
            }

            var coverPromise = coverIconSrc ? this._getImage(coverIconSrc) : null;

            var defaultIconPromise = this._getImage(defaultIconSrc);

            var coverResponded = false;
            var fallbackRes = null;
            coverPromise && coverPromise.then(function (resObj) {
                if (this._isCurrentImage(hash)) {
                    Debug.Control.AudioZone.CoverView && console.log(this.viewId, "_loadImagesAndShow >> cover image received " + coverIconSrc);
                    coverResponded = true;

                    this._handleShowCover(resObj.image, resObj.isLocalSvg, iconObj.rounded, iconObj.color, hash, this._showFullsizeSVG(iconObj));
                }
            }.bind(this), function (err) {
                if (this._isCurrentImage(hash)) {
                    coverResponded = true;

                    if (fallbackRes) {
                        Debug.Control.AudioZone.CoverView && console.warn(this.viewId, "failed to load cover - showing fallbackIcon!");

                        this._applyImgElement(fallbackRes.image, fallbackRes.isLocalSvg, iconObj.rounded, iconObj.color, hash, this._showFullsizeSVG(iconObj));
                    } else {
                        console.warn(this.viewId, "failed to load cover, fallbackIcon not loaded yet or failed too!");
                    }
                }
            }.bind(this));
            defaultIconPromise.then(function (resObj) {
                if (coverResponded) {// ignore default icon
                } else if (this._isCurrentImage(hash)) {
                    Debug.Control.AudioZone.CoverView && console.log(this.viewId, "_loadImagesAndShow >> default icon received " + defaultIconSrc);
                    fallbackRes = resObj; // store in case the cover load fails (due to 404 or sth)
                    // to avoid "flickering" covers when changing tracks or updating the metadata e.g. of the player
                    // introduce a timeout, at least when the view is already visible. This allows for the cover to
                    // be shown directly without showing the default icon in the meantime.

                    if (this.isVisible()) {
                        Debug.Control.AudioZone.CoverView && console.log(this.viewId, "      already visible, delay applying default icon");
                        setTimeout(function () {
                            if (this._isCurrentImage(hash) && !coverResponded) {
                                this._applyImgElement(resObj.image, resObj.isLocalSvg, iconObj.rounded, iconObj.color, hash, this._showFullsizeSVG(iconObj));
                            } else {
                                Debug.Control.AudioZone.CoverView && console.log(this.viewId, "      cover already loaded in the meantime, don't show default icon");
                            }
                        }.bind(this), 500);
                    } else {
                        this._applyImgElement(resObj.image, resObj.isLocalSvg, iconObj.rounded, iconObj.color, hash, this._showFullsizeSVG(iconObj));
                    }
                }
            }.bind(this), function (err) {
                if (this._isCurrentImage(hash)) {
                    console.error(this.viewId, "Failed to load fallbackIconSrc! '" + defaultIconSrc + "'");
                }
            }.bind(this));
            return promise;
        }

        _showFullsizeSVG(iconObj) {
            return !!iconObj.fullSizeSvg;
        }

        _isCurrentImage(hash) {
            return this._currIconHash === hash;
        }

        _getImage(iconSrc) {
            var isLocalSvg = false;
            var imgPrms;
            if(iconSrc instanceof Blob) {
                imgPrms = Promise.resolve(iconSrc);
            } else {
                isLocalSvg = iconSrc.hasPrefix("resources/Images");
                if (isLocalSvg) {
                    imgPrms = ImageBox.getImageElement(iconSrc, 'cover-placeholder__icon');
                } else {
                    // the icon source may need to be adopted when viaproxy parameter has been inserted by audioserver.
                    iconSrc = this.replaceWithAudioserverProxyHost(iconSrc);
                    imgPrms = ImageLoader.shared().get(iconSrc, null, ImageLoader.RESPONSE_TYPE.SVG);
                }
            }

            return imgPrms.then(function (res) {
                return {
                    image: res,
                    isLocalSvg: isLocalSvg
                };
            }.bind(this));
        }

        /**
         * Some icons are downloaded via the audioservers websocket URL, but via http instead of the ip/host provided.
         * The AS will mark URLs with the "viaproxy" url-parameter, who's value specifies which audioserver it's from.
         * @param iconSrc   the iconSrc to check
         * @returns {*} the adopted iconSrc, if required - otherwise it'll pass through unchanged
         * @private
         */
        replaceWithAudioserverProxyHost(iconSrc) {
            var adoptedIconSrc = iconSrc,
                asUuid,
                currentHostInfo;

            try {
                if (iconSrc.indexOf("viaproxy=") >= 0) {
                    var urlObj = new URL(iconSrc.hasPrefix("http") ? iconSrc : "http://" + iconSrc);
                    var urlParams = urlObj.searchParams;
                    asUuid = urlParams.get('viaproxy');
                    currentHostInfo = this._getAudioserverHostInfo(asUuid);

                    if (currentHostInfo) {
                        urlObj.host = currentHostInfo.host;
                        urlObj.pathname = (currentHostInfo.pathname + urlObj.pathname).replace("//", "/");
                        urlObj.protocol = currentHostInfo.protocol;
                        urlObj.port = currentHostInfo.port;
                        urlObj.searchParams.delete("viaproxy");
                        adoptedIconSrc = urlObj.toString();
                        Debug.Control.AudioZone.CoverView && console.warn(this.viewId, "replaceWithAudioserverProxyHost: hostInfo=" + JSON.stringify(currentHostInfo));
                        Debug.Control.AudioZone.CoverView && console.warn(this.viewId, iconSrc);
                        Debug.Control.AudioZone.CoverView && console.warn(this.viewId, adoptedIconSrc);
                    } else {
                        console.error(this.viewId, "replaceWithAudioserverProxyHost: no AS-Component found with UUID " + JSON.stringify(asUuid));
                    }
                }
            } catch (ex) {
                console.error(this.viewId, "replaceWithAudioserverProxyHost Failed! " + JSON.stringify(ex));
                console.error(this.viewId, ex);
            }

            return adoptedIconSrc;
        }

        _getAsComp(uuid) {
            var comp = null;

            if (window.audioserverComps) {
                comp = window.audioserverComps[uuid];
            } else {
                console.warn(this.name, "Failed to _getAsComp - window.audioserverComps doesn't exist!");
            }

            return comp;
        }

        _getAudioserverHostInfo(uuid) {
            var result, urlObj;

            if (this._getAsComp(uuid) && this._getAsComp(uuid).connectionUrl) {
                try {
                    urlObj = new URL(this._getAsComp(uuid).connectionUrl);
                    result = {
                        host: urlObj.host,
                        pathname: urlObj.pathname,
                        port: urlObj.port,
                        protocol: urlObj.protocol
                    };
                } catch (ex) {
                    console.error(this.name, "Failed to _getAudioserverHostInfo of UUID: " + uuid);
                    console.error(this.name, ex);
                }
            }

            return result;
        }

        /**
         * In order to have a speedy UI, wait until the coverView is already visible before showing the icon!
         * @param image
         * @param isLocalSvg
         * @param rounded
         * @param iconColor
         * @param hash
         * @returns {Q.Promise<unknown>}
         * @private
         */
        _handleShowCover(image, isLocalSvg, rounded, iconColor, hash, isFullSizeSVG) {
            if (Debug.Test.DELAY_COVERS) {
                var showCoverDef = Q.defer();
                Debug.Control.AudioZone.CoverView && console.log(this.viewId, "_handleShowCover, processWhenVisible");
                this.processWhenVisible(function () {
                    Debug.Control.AudioZone.CoverView && console.log(this.viewId, "_handleShowCover, visible - show");

                    this._applyImgElement(image, isLocalSvg, rounded, iconColor, hash, isFullSizeSVG).then(function () {
                        showCoverDef.resolve();
                    }, function () {
                        showCoverDef.reject();
                    });
                }.bind(this));
                return showCoverDef.promise;
            } else {
                return this._applyImgElement(image, isLocalSvg, rounded, iconColor, hash);
            }
        }

        _applyImgElement(image, isLocalSvg, rounded, iconColor, hash, showFullsizeSVG) {
            if (hash && !this._isCurrentImage(hash)) {
                Debug.Control.AudioZone.CoverView && console.warn(this.viewId, "_applyImgElement - hash outdated!");
                Debug.Control.AudioZone.CoverView && console.warn(this.viewId, "         img has: " + hash);
                Debug.Control.AudioZone.CoverView && console.warn(this.viewId, "       curr hash: " + this._currIconHash);
                return Q.resolve(this.viewId + ": _applyImgElement - outdated cover received!");
            }

            if (!this.element) {
                // happens when the image resolves, but the view is no longer around.
                Debug.Control.AudioZone.CoverView && console.warn(this.viewId, "_applyImgElement - no this.element!");
                return Q.resolve(this.viewId + ": _applyImgElement - No this.element! " + JSON.stringify(getStackObj()));
            }

            if (this._updateImgPromise) {
                Debug.Control.AudioZone.CoverView && console.warn(this.viewId, "_applyImgElement called while image is being updated! save for later!");
                return this._updateImgPromise.then(this._applyImgElement.bind(this, image, isLocalSvg, rounded, iconColor, hash));
            }

            Debug.Control.AudioZone.CoverView && console.log(this.viewId, "_applyImgElement");
            var clone = this.element.clone(true);

            if (PlatformComponent.isIOS() && image instanceof Blob) {
                const reader = new FileReader();
                reader.onload = function () {
                    const imgElement = $('<img class="cover-placeholder__icon" src="' + reader.result + '" />')
                    imgElement.css('width', '100%');
                    imgElement.css('height', '100%');
                    this.coverElement.empty();
                    this.coverElement.append(imgElement);
                }.bind(this);
                reader.readAsDataURL(image);
            }

            let replaceDef = Q.defer();
            if(!PlatformComponent.isIOS() && image instanceof Blob) {
                const objUrl = URL.createObjectURL(image);
                const tmpImg = new Image();
                tmpImg.onload = function() {
                    replaceDef.resolve();
                    URL.revokeObjectURL(objUrl);
                };
                tmpImg.onerror = function() {
                    replaceDef.reject();
                    URL.revokeObjectURL(objUrl);
                };
                tmpImg.src = objUrl;
            } else {
                replaceDef.resolve();
            }
            var replacePromise = replaceDef.promise.then(() => GUI.animationHandler.replace(this.element, clone)).then(function () {
                if (image[0] instanceof SVGImageElement) {
                    try {
                        // This will set the image as big as the element, it basically fills the image by preserving the aspect Ratio
                        image[0].setAttributeNS(null, 'preserveAspectRatio', "xMidYMin slice"); // yMin as otherwise some covers, e.g. radio stations ORF NÖ have been shifted on the right outside of the cover.
                    } catch (e) {
                        console.log("setAttributeNS preserveAspectRatio failed!");
                    }
                } else if (!PlatformComponent.isIOS() && image instanceof Blob) {
                    const blobUrl = URL.createObjectURL(image);
                    const imgElement = $('<img class="cover-placeholder__icon" />');
                    imgElement.css('width', '100%');
                    imgElement.css('height', '100%');
                    imgElement.on('load', function() {
                        URL.revokeObjectURL(blobUrl);
                    });
                    imgElement.on('error', function() {
                        URL.revokeObjectURL(blobUrl);
                    });
                    imgElement.attr('src', blobUrl);
                    this.coverElement.empty();
                    this.coverElement.append(imgElement);
                }

                var coverPlaceholder = this.coverElement.find(".cover-placeholder__icon"),
                    backgroundElm = '<rect class="icon--bg" x="0" y="0" width="100%" height="100%"/>',
                    // Is the typical icon shape (square with rounded corners)
                    coverPlaceholderClassAttr = coverPlaceholder.attr("class") || "";
                this.coverElement.removeClass("cover-placeholder--default");

                if (rounded) {
                    coverPlaceholder.attr("class", coverPlaceholderClassAttr + " cover-placeholder__icon--rounded");
                } else {
                    coverPlaceholder.attr("class", coverPlaceholderClassAttr.replace("cover-placeholder__icon--rounded", ""));
                }

                if (image[0] instanceof SVGElement && isLocalSvg) {
                    // Check if it is a local SVG
                    image.css("fill", iconColor);

                    if (this.coverElement) {
                        var sizeTransformation = showFullsizeSVG ? "" : "transform='matrix(0.5, 0, 0, 0.5, 6, 6)'"; // Embed the SVG element into a group to be able to apply styles to it!

                        coverPlaceholder.attr("class", coverPlaceholderClassAttr + " cover-placeholder__icon--local-svg");
                        coverPlaceholder.html(backgroundElm + "<g class='icon--local-svg' " + sizeTransformation + ">" + image[0].outerHTML + "</g>");
                    }
                } else {
                    if (this.coverElement) {
                        coverPlaceholder.attr("class", (coverPlaceholderClassAttr || "").replace(" cover-placeholder__icon--local-svg", ""));
                        if(Array.isArray(image)) {
                            coverPlaceholder.html(backgroundElm + image[0].outerHTML);
                        }
                    }
                }

                return GUI.animationHandler.replace(clone, this.element);
            }.bind(this)).catch(() => {
                Debug.Control.AudioZone.CoverView && console.error(this.viewId, "Failed to replace cover image!");
            });
            this._updateImgPromise = replacePromise.then(function () {
                this._updateImgPromise = null;
            }.bind(this));
            return this._updateImgPromise;
        }

    }

    Controls.AudioZoneV2Control.CoverViewV2 = CoverView;
    return Controls.AudioZoneV2Control.CoverViewV2;
});
