import Dexie from 'dexie';

// Create a Dexie instance and define your database schema
export const globalCacheDB = new Dexie('GlobalCache');

// Define the schema with an additional `timestamp` field
globalCacheDB.version(1).stores({
    audioZoneImages: '&url, blob, timestamp', // Add 'timestamp' field
});

// Open the database and delete old entries based on timestamp
globalCacheDB.open()
    .then(() => {
        // Assuming timestamp exists and is stored as a Unix timestamp (milliseconds)
        const currentTime = new Date().getTime();
        const thresholdTime = currentTime - 1000 * 60 * 60 * 24 * 7; // 1 week

        // Delete entries where the 'timestamp' is older than the threshold
        return globalCacheDB.audioZoneImages
            .where('timestamp')
            .below(thresholdTime)
            .delete();
    })
    .then(() => {
        console.log('Old records deleted successfully');
    })
    .catch((err) => {
        console.error('Failed to open IndexedDB or delete records', err);
    });
