import {
    useControl,
    useLiveState,
    LxReactTableView,
    LxReactText,
    LxReactQuickSelect,
    LxReactLinkedControlsList,
    DateViewType,
    LxReactControlStatsScreen,
    LxReactContextMenuHandler,
    LxControlNoteView,
    LxControlContextProvider,
    LxReactPressable
} from "LxComponents"
import { View, ScrollView } from "react-native";
import { useMemo } from "react";
import globalStyles from "GlobalStyles";
import ChargingInfo from "./chargingInfo";
import WallboxStateIcon from "./wbStateIcon";
import WbModeSettings from "./wbModeSettings";
import WbHistory from "./wbHistory";
import Wb2Enums from "./wallboxEnums";
import Icons from "IconLib";

export default function Wallbox2ControlContent({ navigation, route }) {
    const ctrlUuid = route.params.controlUUID;
    const control = useControl(ctrlUuid);
    const liveState = useLiveState(ctrlUuid,
        [
            "limitMode",
            "lastSession",
            "limitModeId",
            "limit",
            "stateColor",
            "stateTextForContent",
            "loadSheddingActive",
            "carConnected",
            "isBelowLimit",
            "modes",
            "isCharging",
            "sessionHistory",
            "needsHistory",
            "phaseSwitching",
            "priority",
            "pricePerHour",
            "pricePerKWh",
            "notEnoughPowerAssigned"
        ]);

    const renderNotificationBar = () => {
        let notificationText = liveState.states.stateTextForContent,
            textColor = liveState.states.stateTextColor;

        if (liveState.states.isBelowLimit ||
            liveState.states.loadSheddingActive ||
            liveState.states.phaseSwitching ||
            liveState.states.notEnoughPowerAssigned
        ) {
            textColor = globalStyles.colors.orange;
        } else if (liveState.states.carConnected) {
            textColor = globalStyles.colors.stateActive;
        }

        return <LxReactText numberOfLines={1} style={{ ...Wb2Enums.styles.notificationText, ...(textColor ? { color: textColor } : {}) }}>
            {notificationText}
        </LxReactText>;
    }

    // region table callbacks

    const showModeSettings = () => {
        navigation.navigate(WbModeSettings.name, { controlUuid: ctrlUuid });
    }

    const showHistory = () => {
        navigation.navigate(WbHistory.name, { controlUuid: ctrlUuid });
    }

    const showConsumptionStats = () => {
        navigation.navigate(LxReactControlStatsScreen.name, {
            controlUuid: ctrlUuid,
            availableViewTypes: Object.values(DateViewType).filter(vtVal => vtVal !== DateViewType.Live),
            title: _("controls.fronius.consumption"),
            outputNames: ["total"],
            format: control.totalFormat,
            legend: control.totalEnergyStatLegend,
            colorInfo: control.totalEnergyStatColor.colorInfo,
        })
    }

    const showPowerStats = () => {
        navigation.navigate(LxReactControlStatsScreen.name, {
            controlUuid: ctrlUuid,
            availableViewTypes: [DateViewType.Live],
            title: _("controls.wallbox2.power"),
            outputNames: ["actual"],
            format: control.actualFormat,
            legend: control.actualPowerStatLegend,
            forcedColors: control.actualPowerStatColor.forcedColors,
            colorInfo: control.actualPowerStatColor.colorInfo,
            allowPastLive: true
        })
    }

    // endregion

    // region table content creation

    /**
     * Gets selectable mode-options, also minds that one without a name may currently be selected.
     * @returns {*}
     */
    const getModeSelectionOptions = () => {
        let options = control.modes;
        options = options.map(modeObj => { return { title: modeObj.name, value: modeObj.id } });
        options = options.filter(option => { return !!option.title || option.value === liveState.states.limitModeId; });
        options = options.map(option => {
            let res = { ...option };
            if (!option.title && option.value === liveState.states.limitModeId) {
                res.title = (_("controls.wallbox2.mode") + " " + option.value)
            }
            return res;
        });
        return options;
    }

    const changeMode = (modeId) => {
        const pastLimit = liveState.states.limit
        control.changeToMode(modeId)

        if (modeId == control.manualModeId) {
            showModeLimitSlider(control.getLimitModeObj(control.manualModeId), control.getLimitForManualMode(pastLimit)) 
        }
    }

    const createModeSelectionRow = () => {
        let selectedValue = liveState.states.limitModeId,
            title = _("controls.wallbox2.charging-mode"),
            selectionOptions = getModeSelectionOptions(),
            handleSelectedFn = (modeId) => { changeMode(modeId) };

        // safety net for fresh wallbox2Controls that have not yet selected an option!
        if (selectedValue === 0 || selectionOptions.length === 0) {
            selectionOptions.splice(0, 0, {
                title: "--",
                value: 0
            });
        }

        return {
            title: title,
            titleStyle: Wb2Enums.styles.cellTitle,
            cellElementStyle: Wb2Enums.styles.cellElement,
            mainRightContent: {
                comp: LxReactQuickSelect,
                props: {
                    selectedValue: selectedValue.toString(),
                    options: selectionOptions,
                    noToggle: true,
                    containerStyle: {
                        flex: 1,
                        alignItems: "flex-end"
                    },
                    titleStyle: {
                        ...globalStyles.textStyles.body.default,
                        color: globalStyles.colors.stateActive,
                        paddingRight: 0
                    },
                    tintColor: globalStyles.colors.text.secondary,
                    iconComp: Icons.ChevronsUpDown,
                    iconPosition: LxReactQuickSelect.IconPosition.Right,
                    onOptionSelected: handleSelectedFn,
                    truncateOptionLabel: 30
                }
            },
            mainCenterStyle: {
                flexShrink: 0,
                flexBasis: "min-content",
                flexDirection: "row"
            },
            mainRightStyle: {
                flexShrink: 1,
                flexDirection: "row"
            }
        };
    }

    const createInfoRow = (title, stateText, subtitleText, subtitleColor, action, stateValueColor) => {
        let row = {
            title: title,
            titleStyle: Wb2Enums.styles.cellTitle,
            cellElementStyle: Wb2Enums.styles.cellElement,
            mainRightContent: {
                comp: LxReactText,
                props: {
                    children: stateText,
                    isLoading: !!stateText,
                    style: {...Wb2Enums.styles.stateValue, ...(stateValueColor ? { color: stateValueColor } : {})}
                }
            },
        }

        if (action) {
            row.onPress = action;
        }

        if (subtitleText) {
            row.bottomContent = {
                comp: LxReactText,
                props: {
                    children: subtitleText,
                    isLoading: false,
                    style: { ...Wb2Enums.styles.cellFooter, ...(subtitleColor ? { color: subtitleColor } : { color: globalStyles.colors.text.secondary }) }
                }
            }
        }

        return row;
    }
    const createActionRow = (title, action) => {
        return {
            title: title,
            titleStyle: Wb2Enums.styles.cellTitle,
            cellElementStyle: Wb2Enums.styles.cellElement,
            onPress: action,
            disclosureIcon: true
        }
    }

    const handleAdoptChargingLimit = () => {
        // featurecheck here
        if (Feature.WALLBOX2_UPDATED_BLOCK && !(liveState.states.limitModeId === control.manualModeId)) {
            return
        } else {
            showModeLimitSlider(liveState.states.limitMode);
        }
    }

    // valueOverride to not wait for update of limit value when changing to manualMode
    // but compute it ourself 
    const showModeLimitSlider = (mode, valueOverride=null) => {
        const sliderFormat = control.actualFormat.split(/(\.\df).+$/)
        let options = {
            value: valueOverride ?? mode.value,
            format: [sliderFormat[0], sliderFormat[1]].join(""), // no unit, provided in description
            minValue: control.minChargingPower,
            maxValue: control.maxChargingPower,
            step: control.getStepSize(control.actualFormat),
            onSliderDisappear: (value) => {
                if (value !== mode.value) {
                    control.updateMode(mode.id, mode.name, value);
                }
            }
        };
        let title = `${_("controls.wallbox2.charging-power-limit")} [${lxSplitFormat(control.actualFormat).succTxt.replace(" ", "")}]`
        LxReactContextMenuHandler.shared.showContextMenu(options, title, null, GUI.LxSliderContextMenu);
    }

    const createChargingLimitRow = (title, subtitle, subtitleColor, action) => {
        let currentSelectedMode = liveState.states.limitMode;
        let stateValueColor;
        if(currentSelectedMode && currentSelectedMode.id === control.manualModeId) {
            stateValueColor = currentSelectedMode && currentSelectedMode.id === control.manualModeId ? globalStyles.colors.stateActive : globalStyles.colors.text.secondary;
        }
        return createInfoRow(_("controls.wallbox2.charging-power-limit"), title, subtitle, subtitleColor, action, stateValueColor);
    }


    const managedByFooterTitle = useMemo(() => {
        let managedByGeneric = _("controls.wallbox2.managed-by-generic");
        if (control?.isManaged && control?.managedByOCPP) {
            let content = [];
            content.push((<LxReactText children={managedByGeneric} />));
            if (control?.manager) {
                content.push((
                    <LxReactPressable
                        style={{color: globalStyles.colors.brand}}
                        onPress={() => {
                            NavigationComp.showControlContent(control?.manager)
                        }}
                    >
                        {control?.managerName}
                    </LxReactPressable>
                ))
            } else {
                content.push((<LxReactText children={control?.managerName} />));
            }
            content.push((<LxReactText children={", "} />));
            content.push((<LxReactText children={control?.ocppPluginName} />));
            return (<LxReactText children={content} />);
        } else if (control?.isManaged) {
            if(control?.manager) {
                const translatedParts = _("controls.wallbox2.substituted-managed-description", {
                    managerName: '<replace>',
                }).split('<replace>');
                const clickableName = (
                    <LxReactPressable
                        style={{color: globalStyles.colors.brand}}
                        onPress={() => {
                            NavigationComp.showControlContent(control?.manager)
                        }}
                    >
                        {control?.managerName}
                    </LxReactPressable>
                );
                const content = [];
                content.push(translatedParts[0]);
                content.push(clickableName);
                content.push(translatedParts[1]);
                return (<LxReactText children={content} />);
            }
            return _("controls.wallbox2.managed-description");
        } else if (control?.managedByOCPP) {
            return managedByGeneric + control?.ocppPluginName;
        }
    }, [control?.ocppPluginName, control?.managerName]);

    const unmanagedModeSection = useMemo(() => {
        if (control?.isManaged) {
            return null;
        }

        let unmanagedModeSection = {
            rows: [],
            headerStrongTitle: _("controls.wallbox2.mode"),
            headerTitleStyle: Wb2Enums.styles.sectionHeaderTitleStyle,
        },
            chargeLimitTxt,
            chargeLimitSubtext,
            chargeLimitSubtextColor;

        if (liveState.states.limitMode) {
            chargeLimitTxt = lxUnitConverter.convertAndApply(control.actualFormat, liveState.states.limitMode.value);

            if (liveState.states.isBelowLimit && !liveState.states.loadSheddingActive) {
                chargeLimitSubtext = _("controls.wallbox2.below-limit");
                chargeLimitSubtextColor = globalStyles.colors.orange;
            } else if (liveState.states.limitMode.setByLogic) {
                chargeLimitSubtext = _("controlled-via-logic");
            }
            unmanagedModeSection.rows.push(createModeSelectionRow());
        } else {
            unmanagedModeSection.rows.push(createInfoRow(_("controls.wallbox2.charging-mode")));
        }

        let limitAction = null;
        if (
            liveState.states.limitMode &&
            !liveState.states.limitMode.setByLogic &&
            liveState.states.limitMode.id === control.manualModeId) {
            limitAction = handleAdoptChargingLimit;
        }
        unmanagedModeSection.rows.push(createChargingLimitRow(chargeLimitTxt, chargeLimitSubtext, chargeLimitSubtextColor, limitAction));

        if (Feature.WALLBOX2_UPDATED_BLOCK) {
            if (ActiveMSComponent.getCurrentUser().isAdmin)
                unmanagedModeSection.rows.push(createActionRow(_("settings"), showModeSettings))
        } else {
            unmanagedModeSection.rows.push(createActionRow(_("settings"), showModeSettings));
        }

        unmanagedModeSection.footerTitle = managedByFooterTitle;

        return unmanagedModeSection;
    }, [
        liveState.states.limitModeId,
        liveState.states.isBelowLimit,
        liveState.states.loadSheddingActive,
        liveState.states.limitMode,
        liveState.states.modes,
        control.modes,
        liveState.states.isCharging,
        control?.isManaged,
        control?.managedByOCPP
    ]);


    const managedModeSection = useMemo(() => {
        if (!control?.isManaged) {
            return null;
        }
        const rows = [],
            limitTxt = lxUnitConverter.convertAndApply(control.actualFormat, liveState?.states?.limit),
            currencyFormat = ActiveMSComponent.getCurrencyFormat();

        rows.push(createInfoRow(_("controls.wallbox2.charging-mode"),
            liveState?.states?.priority ?
                _("controls.wallbox2.managed-prio-mode") :
                _("controls.wallbox2.managed-eco-mode")));
        rows.push(createInfoRow(_("controls.wallbox2.charging-power-limit"), limitTxt));
        
        if (!control.shouldHidePrice(liveState?.states?.pricePerKWh) && control?.getMeterIsConnected) {
            const pricePerKWh = lxFormat(currencyFormat, liveState?.states?.pricePerKWh);
            rows.push(createInfoRow(_("controls.wallbox2.charging-fee-per-kwh"), pricePerKWh));
        }
        if (!control.shouldHidePrice(liveState?.states?.pricePerHour)) {
            const pricePerHour = lxFormat(currencyFormat, liveState?.states?.pricePerHour);
            rows.push(createInfoRow(_("controls.wallbox2.fee-per-hour"), pricePerHour));
        }

        return {
            headerStrongTitle: _("controls.wallbox2.mode"),
            headerTitleStyle: Wb2Enums.styles.sectionHeaderTitleStyle,
            rows,
            footerTitle: managedByFooterTitle
        };
    }, [
        control?.isManaged,
        control?.managedByOCPP,
        liveState?.states?.priority,
        liveState?.states?.pricePerHour,
        liveState?.states?.pricePerKWh,
        liveState?.states?.limit
    ]);

    const getDateTimeDescription = ({connect, disconnect}) => {
        if (!connect || ! disconnect) {
            return null;
        }

        return LxDate.formatDateRange(connect, disconnect, true);
    }

    const lastChargeSection = useMemo(() => {
        let lastChargeSection,
            session = liveState.states.lastSession,
            energyText, userIdText, costTxt;

        if (session) {
            lastChargeSection = {};

            lastChargeSection.headerDescription = getDateTimeDescription(session);
            lastChargeSection.rows = [];

            if (control?.getMeterIsConnected) {
                energyText = lxUnitConverter.convertAndApply(control.totalFormat, session.energy);
                lastChargeSection.rows.push(createInfoRow(_("controls.wallbox2.charged-energy"), energyText));
            }

            if (session.price && !control.shouldHidePrice(session.price)) {
                costTxt = lxFormat(ActiveMSComponent.getCurrencyFormat(), session.price);
                lastChargeSection.rows.push(createInfoRow(_("controls.wallbox2.total-cost"), costTxt));
            }
            if (session.extAuthority) {
                lastChargeSection.rows.push(createInfoRow(_("controls.wallbox2.ext-approval"), session.extAuthority));
            }
            if (session.user) {
                userIdText = session.user || "--";
                lastChargeSection.rows.push(createInfoRow(_("controls.wallbox2.user-id"), userIdText));
            }
            if (liveState.states.needsHistory) {
                lastChargeSection.rows.push(createActionRow(_("history"), showHistory));
            }
            lastChargeSection.headerStrongTitle = _("controls.wallbox2.last-charge");
            lastChargeSection.headerTitleStyle = Wb2Enums.styles.sectionHeaderTitleStyle;
        }
        return lastChargeSection;
    }, [liveState.states.lastSession, liveState.states.needsHistory]);

    const statSection = useMemo(() => {
        let statSection;
        if (control.supportsStatisticV2) {
            statSection = {};
            statSection.rows = [
                createActionRow(_("controls.fronius.consumption"), showConsumptionStats),
                createActionRow(_("controls.wallbox2.power"), showPowerStats),
            ];
            statSection.headerStrongTitle = _("statistics");
            statSection.headerTitleStyle = Wb2Enums.styles.sectionHeaderTitleStyle;
        }
        return statSection;
    }, [control.supportsStatisticV2]);

    const getTableContent = () => {
        let tableContent = [];

        tableContent.pushObject(unmanagedModeSection);
        tableContent.pushObject(managedModeSection);
        tableContent.pushObject(lastChargeSection);
        control.getMeterIsConnected && tableContent.pushObject(statSection);

        return tableContent;
    };

    const controlNotes = useMemo(() => {
        return control && control.hasControlNotes ?
            <LxControlContextProvider controlUuid={ctrlUuid} isAlert={false}>
                <LxControlNoteView />
            </LxControlContextProvider>
            : null;
    }, [control && control.hasControlNotes, ctrlUuid])

    // endregion

    return (<View style={Wb2Enums.styles.rootCntr}>
        <View style={Wb2Enums.styles.notificationCntr}>{renderNotificationBar()}</View>
        <ScrollView style={Wb2Enums.styles.contentCntr}>
            {controlNotes}
            <WallboxStateIcon controlUuid={ctrlUuid} />
            <ChargingInfo controlUUid={ctrlUuid} />
            <LxReactTableView tableContent={getTableContent()} />
            <LxReactLinkedControlsList controlUuid={ctrlUuid} />
        </ScrollView>
    </View>)
}
